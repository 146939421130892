accounting = require('accounting')

window.Algorand.AuctionBankDepositRequests =
  new: ($$, $this) =>
    class NewView extends Backbone.View
      CRYPTO_DECIMAL_PRECISION = 6

      initialize: ->
        @conversionRates = @$el.data('conversion-rates')
        @usdfundingMethod = @$el.data('usd-funding-method')
        @disabledClass = @$el.data('disabled-class')
        @depositsPath = @$el.data('deposits-path')

        @$fundingMethod = @$('.js-funding-method')
        @fundingMethod = @$('.js-funding-method[checked=checked]').val()

        @$cryptoConversion = @$('.js-crypto-conversion')
        @$submitButton = @$('.js-deposit-button')
        @$submitButtonAmount = @$('.js-deposit-button-amount')

      _inputAmount: ->
        accounting.unformat(@$('.js-amount-input').val())

      _displayDollarAmount: ->
        accounting.formatMoney(@_inputAmount())

      _displayCryptoAmount: (fundingMethod) ->
        amount = @_inputAmount()/@conversionRates[fundingMethod]
        accounting.formatMoney(amount, '', CRYPTO_DECIMAL_PRECISION)

      events: ->
        'input .js-amount-input': (event) ->
          amount = @_inputAmount()
          @$cryptoConversion.toggle(amount > 0)
          @$submitButton.toggleClass(@disabledClass, amount == 0)

          if amount <= 0
            @$submitButtonAmount.text('')
            @$('.js-funding-method-box').find('.js-amount').html('&nbsp;')
          else
            @$submitButtonAmount.text(@_displayDollarAmount())

            # update amounts in funding_method boxes
            _.each(@$fundingMethod, (element) =>
              $element = @$(element)
              $amount = $element.closest('.js-funding-method-box').find('.js-amount')

              fundingMethod = $element.val()
              if fundingMethod == @usdfundingMethod
                $amount.html(@_displayDollarAmount())
              else
                $amount.html('~' + @_displayCryptoAmount(fundingMethod) + '*')
            )

        'change .js-funding-method': (event) ->
          $target = @$(event.target)
          @fundingMethod = $target.val()

        'click .js-deposit-button': (event) ->
          $target = @$(event.target)
          return false if $target.hasClass(@disabledClass)

          $target.addClass(@disabledClass)
          @$('form').ajaxSubmit
            success: =>
              window.location.href = @depositsPath
            error: =>
              Tooltips.formServerError($target)

    new NewView el: $this

  index: ($$, $this) ->
    class IndexView extends Backbone.View
      events: ->
        'click .js-remove': (event) ->
          $target = @$(event.target)
          $target.closest('form').ajaxSubmit
            success: =>
              $target.closest('.js-deposit-request').slideUp()
            error: =>
              Tooltips.formServerError($target)

    new IndexView el: $this
