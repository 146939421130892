
window.Algorand.AlgoCustodyAddresses = {

  new: ($$, $this) =>
    class NewView extends Backbone.View

      events:
        'click .js-wallet_help_link': (event) ->
          @$('.js-wallet_help').show()
          $.magnificPopup.open({
            items: {
              src: @$('.js-wallet_help')
              type: 'inline'
            }
          })

    new NewView el: $this

}