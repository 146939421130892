Chart = require('chart.js')

window.Auctions.MultiSellerAuctions.Participants.Charts =
  header: ($$, $this) ->
    class HeaderView extends Backbone.View
      reload: ->
        $.ajax
          url: @$el.data('chart-header-path')
          success: (data) =>
            if data.partial_html
              @$el.replaceWith(data.partial_html)

    new HeaderView el: $this

  price_chart: ($$, $this) ->
    class PriceChartView extends Backbone.View
      USD = 'USD'
      TICK_LABEL_TYPE = 'tick'
      ORDER_TICK_TYPE = 'order'
      LOCKIN_TICK_TYPE = 'lockin'
      SETTLEMENT_TICK_TYPE = 'settlement'

      BUY_ORDER_SIDE = 'buy'
      SELL_ORDER_SIDE = 'sell'

      LINE_COLOR = '#1581f3'
      GRID_LINE_COLOR = '#eaebec'
      LABEL_COLOR = '#333'

      initialize: ->
        Chart.platform.disableCSSInjection = true
        Chart.defaults.global.defaultFontFamily = '"Akkurat", "Helvetica Neue", sans-serif'

        @$priceLowerBound = @$el.data('price-lower-bound')
        @$priceUpperBound = @$el.data('price-upper-bound')

        chartData = @$el.data('chart-data')
        @priceChart = new Chart @$('.js-chart'),
          type: 'line'
          data: @_chartData(chartData)
          options: @_chartOptions(chartData)

      _chartData: (chartData) ->
        {
          labels: _.map(chartData.labels, (label) -> label.date)
          datasets: [
            {
              data: chartData.data,
              steppedLine: true,
              fill: false,
              pointRadius: 0,
              borderColor: LINE_COLOR,
              borderWidth: 2,
            },
          ]
        }

      _chartOptions: (chartData) ->
        chartView = @
        ctx = @$('.js-chart')[0].getContext("2d")

        {
          legend:
            display: false
          layout:
            padding:
              right: 10
              left: 31
          scales:
            xAxes: [{
              type: 'time'
              time:
                displayFormats: {
                  minute: 'MMM D, h:mm a'
                }
                unit: 'minute'
                min: chartData.bounds.min
                max: chartData.bounds.max
              ticks:
                source: 'labels'
                lineHeight: 1.4
                fontColor: LABEL_COLOR
                callback: (label, index, labels) ->
                  labelItem = chartData.labels[index]
                  if labelItem.type == TICK_LABEL_TYPE
                    if labelItem.tick_type == LOCKIN_TICK_TYPE
                      [label, labelItem.label]
                    else
                      [labelItem.label, '']
                  else
                    ''
              gridLines:
                color: GRID_LINE_COLOR
            }]
            yAxes: [{
              ticks:
                maxTicksLimit: 5
                beginAtZero: false
                suggestedMin: @$priceLowerBound
                suggestedMax: @$priceUpperBound
                fontColor: LABEL_COLOR
                callback: (label, _index, _labels) ->
                  chartView._formatAmount(label, chartData.base_currency)
              gridLines:
                color: GRID_LINE_COLOR
                zeroLineColor: GRID_LINE_COLOR
            }]
          hover:
            animationDuration: 0
          responsiveAnimationDuration: 0
          tooltips:
            backgroundColor: 'rgba(255,255,255,0.93)'
            titleFontSize: 14
            titleFontColor: '#666'
            titleSpacing: 10
            titleMarginBottom: 14
            bodyFontSize: 14
            bodyFontColor: '#666'
            xPadding: 14
            yPadding: 14
            cornerRadius: 3
            displayColors: false
            borderColor: '#ddd'
            borderWidth: 1
            caretSize: 8
            callbacks:
              title: (tooltipItem, data) =>
                date = new Date(tooltipItem[0].xLabel)
                "#{date.toDateString()}, #{date.getHours()}:#{date.getMinutes()}"
              label: (tooltipItem, data) =>
                chartView._formatAmount(tooltipItem.yLabel, chartData.base_currency)
          animation:
            duration: 0
            onComplete: ->
              # draw a rectangle between lockin and settlement phases
              lockin_phase_tick_index =
                _.findIndex(chartData.labels, { tick_type: LOCKIN_TICK_TYPE })
              settlement_phase_tick_index =
                _.findIndex(chartData.labels, { tick_type: SETTLEMENT_TICK_TYPE })

              xAxis = this.scales['x-axis-0']
              yAxis = this.scales['y-axis-0']
              lockin_tick_x = xAxis.getPixelForTick(lockin_phase_tick_index)
              settlement_tick_x = xAxis.getPixelForTick(settlement_phase_tick_index)

              rect_width = settlement_tick_x - lockin_tick_x
              rect_height = this.chartArea.bottom - this.chartArea.top
              ctx.fillStyle = '#00000008'
              ctx.fillRect(lockin_tick_x, this.chartArea.top, rect_width, rect_height);

              # display price
              dataset = this.data.datasets[0]
              if dataset.data.length > 0
                last_point_value = _.last(dataset.data)
                i = Object.keys(dataset._meta)[0]
                model = _.last(dataset._meta[i].data)._model

                # display price value
                ctx.fillStyle = LINE_COLOR
                ctx.font = 'bold 14px "Akkurat", "Helvetica Neue", sans-serif'
                ctx.fillText(
                  chartView._formatAmount(last_point_value.y, chartData.base_currency),
                  model.x + 5, model.y + 5
                )

                # display a current time/price indicator
                x_position = Math.min(model.x, settlement_tick_x)

                ctx.beginPath()
                ctx.moveTo(x_position, this.chartArea.top)
                ctx.lineTo(x_position, this.chartArea.bottom)
                ctx.strokeStyle = '#D816A280'
                ctx.lineWidth = 2
                ctx.stroke()

                ctx.beginPath();
                ctx.arc(x_position, model.y, 2, 0, 2 * Math.PI);
                ctx.strokeStyle = LINE_COLOR
                ctx.stroke();

              # show order numbers
              for label, index in chartData.labels
                if label.type == ORDER_TICK_TYPE
                  order_tick_x = xAxis.getPixelForTick(index)
                  order_tick_y = yAxis.getPixelForValue(label.price)

                  ctx.beginPath();
                  ctx.arc(order_tick_x, order_tick_y, 8, 0, 2 * Math.PI);
                  if label.order_side == BUY_ORDER_SIDE
                    ctx.fillStyle = '#00cc00'
                  else if label.order_side == SELL_ORDER_SIDE
                    ctx.fillStyle = '#ff0000'
                  else
                    ctx.fillStyle = '#000'
                  ctx.fill();

                  ctx.fillStyle = '#fff'
                  ctx.font = 'bold 10px "Akkurat", "Helvetica Neue", sans-serif'
                  x_correction = if label.label >= 10 then 5 else 2.4
                  y_correction = 3
                  ctx.fillText(label.label, order_tick_x - x_correction, order_tick_y + y_correction)
        }

      _formatAmount: (amount, base_currency) ->
        if base_currency == USD
          "$#{amount}"
        else
          "#{amount} #{base_currency}"

      reload: ->
        $.ajax
          url: @$el.data('price-chart-data-path')
          success: (chartData) =>
            @priceChart.data = @_chartData(chartData)
            @priceChart.options = @_chartOptions(chartData)
            @priceChart.update()

    new PriceChartView el: $this

  volume_chart: ($$, $this) ->
    class VolumeChartView extends Backbone.View
      GRID_LINE_COLOR = '#eaebec'
      LABEL_COLOR = '#333'

      initialize: ->
        Chart.platform.disableCSSInjection = true
        Chart.defaults.global.defaultFontFamily = '"Akkurat", "Helvetica Neue", sans-serif'

        $chart = @$('.js-chart')
        chartData = @$el.data('chart-data')

        data = {
          labels: ['Buy Volume', 'Sell Volume']
          datasets: [
            {
              data: [chartData.buy, chartData.sell],
              backgroundColor: ['#7ED321', '#D0021B']
              borderWidth: 0,
            },
          ]
        }

        new Chart $chart,
          type: 'horizontalBar'
          data: data
          options:
            legend:
              display: false
            layout:
              padding:
                top: 14
                right: 10
            scales:
              xAxes: [{
                ticks:
                  fontColor: LABEL_COLOR
                  beginAtZero: true
                  min: 0
                  max: 100
                  stepSize: 25
                  callback: (label, _index, _labels) ->
                    "#{label}%"
                gridLines:
                  display: true
                  color: GRID_LINE_COLOR
                  zeroLineColor: GRID_LINE_COLOR
                  drawBorder: false
              }]
              yAxes: [{
                barPercentage: 0.75
                ticks:
                  fontSize: 13
                  beginAtZero: true
                  fontColor: LABEL_COLOR
                gridLines:
                  display: false
                  color: GRID_LINE_COLOR
              }]
            hover:
              animationDuration: 0
            responsiveAnimationDuration: 0
            animation:
              duration: 0
            tooltips:
              backgroundColor: 'rgba(255,255,255,0.93)'
              bodyFontSize: 14
              bodyFontColor: '#666'
              xPadding: 14
              yPadding: 14
              cornerRadius: 3
              displayColors: false
              borderColor: '#ddd'
              borderWidth: 1
              caretSize: 8
              callbacks:
                title: -> ''
                label: (tooltipItem, data) ->
                  tooltipItem.xLabel.toFixed(2) + '%'

    new VolumeChartView el: $this
