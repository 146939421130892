require('jquery-form')
import * as Castle from '@castleio/castle-js'

window.Devise.Registrations = {

  new_form: ($$, $this) ->
    class NewFormView extends Backbone.View

      initialize: ->
        @toggleButtonState()

      focusForm: ->
        @$('.js-form_input[autofocus]').first().focus()

      getFormattedEmail: ->
        $.trim(@$('.js-form_email_input').val())

      validChecks: ->
        if @$('#legal1-checkbox').prop('checked') == undefined
          check1 = true
        else
          check1 = @$('#legal1-checkbox').prop('checked')
        
        if @$('#legal2-checkbox').prop('checked') == undefined
          check2 = true
        else
          check2 = @$('#legal2-checkbox').prop('checked')

        check1 && check2

      # Source: https://ui.dev/validate-email-address-javascript/
      validEmail: ->
        pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        pattern.test(@getFormattedEmail())

      validFirstName: ->
        $.trim(@$('.js-form_first_name_input').val()).length > 1

      validLastName: ->
        $.trim(@$('.js-form_last_name_input').val()).length > 1

      validPassword: ->
        $.trim(@$('.js-form_password_input').val()).length > 9 && $.trim(@$('.js-form_password_confirmation_input').val()) == $.trim(@$('.js-form_password_input').val())

      validForm: ->
        @validEmail() && @validFirstName() && @validLastName() && @validPassword() && @validChecks()

      toggleButtonState: ->
        @$('.js-form_submit').prop('disabled', !@validForm())

      events:
        'submit .js-form': (event) ->
          event.preventDefault()

        'blur .js-form_email_input': (event) ->
          if @validEmail()
            @$('.js-form_email_input_error').hide()
          else
            @$('.js-form_email_input_error').show()

        'blur .js-form_password_input': (event) ->
          if $.trim(@$('.js-form_password_input').val()).length < 10
            @$('.js-form_password_input_error').show()
          else
            @$('.js-form_password_input_error').hide()
          if $.trim(@$('.js-form_password_confirmation_input').val()).length > 1
            if $.trim(@$('.js-form_password_confirmation_input').val()) == $.trim(@$('.js-form_password_input').val())
              @$('.js-form_password_confirmation_input_error').hide()
            else
              @$('.js-form_password_confirmation_input_error').show()

        'blur .js-form_password_confirmation_input': (event) ->
          if $.trim(@$('.js-form_password_confirmation_input').val()) == $.trim(@$('.js-form_password_input').val())
            @$('.js-form_password_confirmation_input_error').hide()
          else
            @$('.js-form_password_confirmation_input_error').show()

        'input .js-form_email_input': (event) ->
          @toggleButtonState()


        'input .js-form_first_name_input': (event) ->
          @toggleButtonState()

        'input .js-form_last_name_input': (event) ->
          @toggleButtonState()

        'input .js-form_password_input': (event) ->
          @toggleButtonState()

        'input .js-form_password_confirmation_input': (event) ->
          @toggleButtonState()

        'input .js-legal-checkboxes': (event) ->
          @toggleButtonState()

        'click .js-form_submit': (event) ->
          event.preventDefault()
          event.preventDefault()
          if castleData?.castlePublishKey
            Castle.createRequestToken().then (token) ->
              $('#castle_request_token').val(token)

          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          $target.closest('form').ajaxSubmit
            success: (response) =>
              userRegisteredEvent = @$el.data('user-registered-event')
              Analytics.trackEvent userRegisteredEvent, {}, =>
                window.location.href = response.redirect

            error: (response) =>
              if response.status == 422
                @$el.replaceWith response.responseJSON.html
              else
                Tooltips.formServerError($target)

    new NewFormView el: $this

}
