AlgoWallet = require('../lib/algo_wallet')
PrettyAlert = require('../lib/pretty_alert')

# Extracted to a private method since this is used by both the new and import functions
saveAddressToServer = (options) ->
  $.ajax
    method: 'POST'
    data: options.data
    url: options.url
    success: =>
      if options.success
        options.success()
    error: =>
      if options.error
        options.error()
      else
        PrettyAlert.alert("There was an error saving your wallet, please reload and try again")

window.Algorand.AlgoWallets = {
  ledger_algos: ($$, $this) ->
    class LedgerAlgosView extends Backbone.View
      initialize: ->
        entityID = @$el.data('entityId')
        address = @$el.data('address')
        @wallet = new AlgoWallet(entityID, address)

      events: ->
        'click a.js-download_pdf': (event) ->
          if @wallet.isSetup()
            @wallet.downloadPDF()
          else
            PrettyAlert.confirm "Your wallet is not initialized in this browser. Would you like to reinitialize it now?",
              success: -> window.location.href = $(event.currentTarget).data('reinitializePath')
        'click a.js-draw_qrcode': (event) ->
          if @wallet.isSetup()
            @wallet.drawQRCode(@$('.js-qrcode_popup'))
          else
            PrettyAlert.confirm "Your wallet is not initialized in this browser. Would you like to reinitialize it now?",
              success: -> window.location.href = $(event.currentTarget).data('reinitializePath')

    new LedgerAlgosView el: $this

    # Return something other than the class to prevent manipulation via Partials.with
    true

  import: ($$, $this) ->
    class ImportWalletView extends Backbone.View
      initialize: ->
        @entityID = @$el.data('entityId')
        @redirectUrl = @$el.data('redirectUrl')
        @saveUrl = @$el.data('saveUrl')
        @passphrase = @$el.data('passphrase')

        @wallet = new AlgoWallet(@entityID)
        @wallet.reset() if @wallet.isSetup()

      events: ->
        'submit form': (event) ->
          event.preventDefault()
          event.stopPropagation()
          @importExistingMnemonic(@$('form'))

      importExistingMnemonic: ($form) ->
        $submit = $form.find('input[type="submit"]')
        return if $submit.hasClass('c-button--loading')
        $submit.find('.c-button').addClass('c-button--loading')

        mnemonic = $form.find('.js-algo_mnemonic').val().replace(/\s+/g, ' ')
        @wallet.importMnemonic mnemonic, @passphrase,
          success: (address) =>
            save_data = {entity_id: @entityID, address: address}
            saveAddressToServer
              data: save_data
              url: @saveUrl
              success: =>
                window.location.href = @redirectUrl
              error: =>
                $submit.removeClass('c-button--loading')
                PrettyAlert.alert("That passphrase couldn't be imported. Please check it is not already in use with another entity.")
          error: =>
            $submit.removeClass('c-button--loading')
            PrettyAlert.alert("That passphrase couldn't be imported. Please check it is correct.")

    new ImportWalletView el: $this

    # Return something other than the class to prevent manipulation via Partials.with
    true

  new: ($$, $this) ->
    class NewWalletView extends Backbone.View
      initialize: ->
        @entityID = @$el.data('entityId')
        @saveUrl = @$el.data('saveUrl')
        @passphrase = @$el.data('passphrase')

        @wallet = new AlgoWallet(@entityID)
        @wallet.reset() if @wallet.isSetup()

      events: ->
        'click .js-new_wallet_confirm a': ->
          @$('.js-new_wallet_confirm').hide()
          @createWallet()
          @$('.js-new_wallet_form').show()

        'click .js-draw_qrcode': ->
          @wallet.drawQRCode(@$('.js-qrcode_popup'))

        'click a.js-download_pdf': ->
          @wallet.downloadPDF()

        'click .js-begin_validation': ->
          @wallet.validateMnemonic()

      createWallet: ->
        $walletForm = @$('form')
        @wallet.create
          passphrase: @passphrase,
          $mnemonicBox: $walletForm.find('.js-algo_mnemonic'),
          $addressInput: $walletForm.find('.js-algo_address'),
          success: (address) =>
            save_data = {entity_id: @entityID, address: address}
            saveAddressToServer data: save_data, url: @saveUrl
            $('.js-post_validation').show()
            $('.js-pre_validation').hide()
            $('.js-hide_post_validation').hide()
          fail: ->
            $('.js-fail_validation').show()
            $('.js-pre_validation').hide()
            $('.js-hide_post_validation').hide()

    new NewWalletView el: $this

    # Return something other than the class to prevent manipulation via Partials.with
    true

  reinitialize: ($$, $this) ->
    class ReinitializeView extends Backbone.View
      initialize: ->
        address = @$el.data('algorandAddress')
        entityID = @$el.data('entityId')
        @redirectUrl = @$el.data('redirectUrl')
        @passphrase = @$el.data('passphrase')
        @wallet = new AlgoWallet(entityID, address)

        if @wallet.isSetup()
          window.location.href = @redirectUrl

      events: ->
        'click a.js-init_wallet': (event) ->
          $button = @$(event.currentTarget)
          return if $button.hasClass('c-button--loading')
          $button.addClass('c-button--loading')
          mnemonic = @$('.js-algo_mnemonic').val().replace(/[0-9.]/g, '').replace(/\s+/g, ' ')
          @wallet.reinitialize mnemonic.trim(), @passphrase,
            success: =>
              window.location.href = @redirectUrl
            error: (address) ->
              $button.removeClass('c-button--loading')
              if address == 'fail'
                PrettyAlert.alert('That does not appear to be a valid passphrase')
              else
                PrettyAlert.alert('That passphrase generated the wrong address: ' + address)

    new ReinitializeView el: $this

    # Return something other than the class to prevent manipulation via Partials.with
    true

  new_transaction: ($$, $this) ->
    class NewTransactionView extends Backbone.View
      initialize: ->
        @algoMultiplier = Math.pow(10, 6)

        @balance = @$el.data('balance')
        @currentFeeAlgos = @$el.data('currentFeeAlgos')
        @currentRound = @$el.data('currentRound')
        @genesisHash = @$el.data('genesisHash')

        address = @$el.data('algorandAddress')
        entityID = @$el.data('entityId')
        @wallet = new AlgoWallet(entityID, address)

        if @wallet.isSetup()
          $('.js-init_success').show()
        else
          $('.js-init_error').show()

        new Cleave('.js-amount', numeral: true, numeralDecimalScale: 6,
                                 numeralPositiveOnly: true,
                                 numeralThousandsGroupStyle: 'thousand')

      events: ->
        'submit form': (event) ->
          event.preventDefault()
          event.stopPropagation()
          if @validateTransaction()
            @confirmTransaction =>
              @sendTransaction()

      validateTransaction: ->
        if isNaN(@amount())
          PrettyAlert.alert("The amount must be a number")
          false
        else if @amount() < 0
          PrettyAlert.alert("The amount must be positive")
          false
        else if @balance < @currentFeeAlgos + @amount()
          PrettyAlert.alert("You have an insufficient balance to send this amount")
          false
        else if !@wallet.isValidAddress(@toAddress())
          PrettyAlert.alert("That address is not a valid Algorand address")
          false
        else
          true

      confirmTransaction: (success) ->
        PrettyAlert.confirm "Do you really want to send " + @amount() + " algos (plus a fee of " +
                            @currentFeeAlgos + " algos per KB) to address " +
                            @toAddress().substring(0, 15) + "...? This cannot be undone!",
          success: success

      sendTransaction: ->
        transaction = {
          'to': @toAddress(),
          'fee': @currentFeeAlgos * @algoMultiplier,
          'amount': @amount() * @algoMultiplier,
          'firstRound': @currentRound,
          'lastRound': @currentRound + 1000,
          'genesisHash': @genesisHash,
          'note': new Uint8Array(0)
        }

        @wallet.signTransaction transaction,
          success: (signedTxn) =>
            signedTxnStr = btoa(String.fromCharCode.apply(null, signedTxn.blob))

            $.ajax
              method: 'POST',
              url: @$('form').attr('action'),
              data: {signed_transaction: signedTxnStr},
              success: (response) ->
                window.location.href = response.redirect
              error: ->
                PrettyAlert.alert('There was an error posting that transaction to the blockchain')

      amount: ->
        parseFloat(@$('.js-amount').val().replace(/,/g, ''))

      toAddress: ->
        @$('.js-address').val()

    new NewTransactionView el: $this

    # Return something other than the class to prevent manipulation via Partials.with
    true
}
