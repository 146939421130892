import * as React from "react";
import {
  Button,
  Checkbox,
  InlineLabel,
  Label,
  Spacer,
  TextInput,
} from "@react/components";
import TableWithTitle from "@react/views/shared/TableWithTitle";
import { useState } from "react";
import { RequestType, typedAxiosRequest } from "@react/utils/network";

interface MinaRewards {
  tokenSaleParticipantsNetRewards: number;
  tokenSaleParticipantsTotalRewards: number;
  tokenSaleParticipantsFigmentFee: number;
  tokenSaleParticipantsCoinListFee: number;
  tokenSaleParticipantsUserRewards: number;
  tokenSaleParticipantsFigmentUrl: string;
  prebackerParticipantsNetRewards: number;
  prebackerParticipantsTotalRewards: number;
  prebackerParticipantsFigmentFee: number;
  prebackerParticipantsCoinListFee: number;
  prebackerParticipantsUserRewards: number;
  prebackerParticipantsFigmentUrl: string;
}

interface Props {
  minaRewards: MinaRewards;
  includeCoinbaseRewards: boolean;
  startDate: string;
  endDate: string;
}

export default function MinaStaking(props: Props) {
  const [coinbaseRewards, setCoinbaseRewards] = useState<boolean>(
    props.includeCoinbaseRewards
  );
  const [startDate, setStartDate] = useState<string>(props.startDate);
  const [endDate, setEndDate] = useState<string>(props.endDate);
  const [minaRewards, setMinaRewards] = useState<MinaRewards>(
    props.minaRewards
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMinaRewards = async () => {
    try {
      setIsLoading(true);
      const { data } = await typedAxiosRequest<MinaRewards>(
        "/admin/stake/mina/get_mina_rewards",
        RequestType.GET,
        null,
        {
          params: {
            start_date: startDate,
            end_date: endDate,
            include_coinbase_rewards: coinbaseRewards,
          },
        }
      );
      setMinaRewards(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const tokenParticipantsRows = [
    [
      "Net Rewards",
      minaRewards.tokenSaleParticipantsNetRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Total Rewards",
      minaRewards.tokenSaleParticipantsTotalRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Figment Fee (2.5%)",
      minaRewards.tokenSaleParticipantsFigmentFee.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "CoinList Fee (12.5%)",
      minaRewards.tokenSaleParticipantsCoinListFee.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "User Rewards",
      minaRewards.tokenSaleParticipantsUserRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
  ];

  const prebackerParticipantsRows = [
    [
      "Net Rewards",
      minaRewards.prebackerParticipantsNetRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Total Rewards",
      minaRewards.prebackerParticipantsTotalRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Figment Fee (2.5%)",
      minaRewards.prebackerParticipantsFigmentFee.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "CoinList Fee (12.5%)",
      minaRewards.prebackerParticipantsCoinListFee.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "User Rewards",
      minaRewards.prebackerParticipantsUserRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
  ];

  const totalRewardsRows = [
    [
      "Total Rewards to Figment",
      (
        minaRewards.tokenSaleParticipantsFigmentFee +
        minaRewards.prebackerParticipantsFigmentFee
      ).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Total Rewards to CoinList",
      (
        minaRewards.tokenSaleParticipantsCoinListFee +
        minaRewards.prebackerParticipantsCoinListFee
      ).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Total Rewards to Users",
      (
        minaRewards.tokenSaleParticipantsUserRewards +
        minaRewards.prebackerParticipantsUserRewards
      ).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
  ];

  return (
    <div>
      <div className="u-displayFlex u-alignItemsCenter">
        <Label>
          Start Date
          <TextInput
            type="date"
            defaultValue={startDate}
            onChange={(event) => setStartDate(event.target.value)}
          />
        </Label>
        <Label>
          End Date (exclusive)
          <TextInput
            type="date"
            defaultValue={endDate}
            onChange={(event) => setEndDate(event.target.value)}
          />
        </Label>
      </div>
      <div className="u-displayFlex">
        <InlineLabel>
          <Checkbox
            checked={coinbaseRewards}
            onChange={() => setCoinbaseRewards(!coinbaseRewards)}
          />
          Include Coinbase Rewards
        </InlineLabel>
        <div className="u-width100 u-displayFlex u-justifyContentFlexEnd">
          <Button
            loading={isLoading}
            size="extra-small"
            onClick={() => getMinaRewards()}
          >
            Apply Changes
          </Button>
        </div>
      </div>
      <Spacer spacing={2.5} />
      <TableWithTitle
        style="bordered"
        rows={tokenParticipantsRows}
        title={
          <a href={minaRewards.tokenSaleParticipantsFigmentUrl}>
            Token Sale Participants
          </a>
        }
      />
      <Spacer spacing={1} />
      <TableWithTitle
        style="bordered"
        rows={prebackerParticipantsRows}
        title={
          <a href={minaRewards.prebackerParticipantsFigmentUrl}>
            Prebacker Participants
          </a>
        }
      />
      <Spacer spacing={1} />
      <TableWithTitle
        style="bordered"
        rows={totalRewardsRows}
        title="Total Rewards"
      />
    </div>
  );
}
