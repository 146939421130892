import * as React from "react";
import {
  Button,
  Checkbox,
  InlineLabel,
  Label,
  Spacer,
  TextInput,
} from "@react/components";
import TableWithTitle from "@react/views/shared/TableWithTitle";
import { useState } from "react";
import { RequestType, typedAxiosRequest } from "@react/utils/network";

interface VegaRewards {
  option1NetRewards: number;
  option1TotalRewards: number;
  option1ValidatorFee: number;
  option1CoinlistFee: number;
  option1UserRewards: number;
  option2NetRewards: number;
  option2TotalRewards: number;
  option2ValidatorFee: number;
  option2CoinlistFee: number;
  option2UserRewards: number;
  liquidTokensNetRewards: number;
  liquidTokensTotalRewards: number;
  liquidTokensValidatorFee: number;
  liquidTokensCoinlistFee: number;
  liquidTokensUserRewards: number;
  coinlistFeesNetRewards: number;
  coinlistFeesTotalRewards: number;
  coinlistFeesValidatorFee: number;
  coinlistFeesCoinlistFee: number;
  coinlistFeesUserRewards: number;
}

interface Props {
  vegaRewards: VegaRewards;
  startDate: string;
  endDate: string;
}

export default function VegaStaking(props: Props) {
  const [startDate, setStartDate] = useState<string>(props.startDate);
  const [endDate, setEndDate] = useState<string>(props.endDate);
  const [vegaRewards, setVegaRewards] = useState<VegaRewards>(
    props.vegaRewards
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getVegaRewards = async () => {
    try {
      setIsLoading(true);
      const { data } = await typedAxiosRequest<VegaRewards>(
        "/admin/stake/vega/get_vega_rewards",
        RequestType.GET,
        null,
        {
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        }
      );
      setVegaRewards(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    console.log("hi");
  };

  const totalAmountToUndelegate =
    vegaRewards.option1UserRewards +
    vegaRewards.option2UserRewards +
    vegaRewards.liquidTokensUserRewards +
    vegaRewards.coinlistFeesUserRewards +
    vegaRewards.option1CoinlistFee +
    vegaRewards.option2CoinlistFee +
    vegaRewards.liquidTokensCoinlistFee +
    vegaRewards.coinlistFeesCoinlistFee;

  const amountToMoveToOperatingAccount =
    vegaRewards.option1CoinlistFee +
    vegaRewards.option2CoinlistFee +
    vegaRewards.liquidTokensCoinlistFee +
    vegaRewards.coinlistFeesUserRewards +
    vegaRewards.coinlistFeesCoinlistFee;

  const coinListNonRebateFees =
    vegaRewards.option1CoinlistFee +
    vegaRewards.option2CoinlistFee +
    vegaRewards.liquidTokensCoinlistFee +
    vegaRewards.coinlistFeesCoinlistFee;

  const coinListFees =
    vegaRewards.option1CoinlistFee +
    vegaRewards.option2CoinlistFee +
    vegaRewards.liquidTokensCoinlistFee;

  const option1Rows = [
    [
      "Net Rewards",
      vegaRewards.option1NetRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Total Rewards",
      vegaRewards.option1TotalRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Validator Fee (11.7%)",
      vegaRewards.option1ValidatorFee.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "CoinList Fee (15%)",
      vegaRewards.option1CoinlistFee.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "User Rewards",
      vegaRewards.option1UserRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
  ];

  const option2Rows = [
    [
      "Net Rewards",
      vegaRewards.option2NetRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Total Rewards",
      vegaRewards.option2TotalRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Validator Fee (11.7%)",
      vegaRewards.option2ValidatorFee.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "CoinList Fee (15%)",
      vegaRewards.option2CoinlistFee.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "User Rewards",
      vegaRewards.option2UserRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
  ];

  const liquidTokenRows = [
    [
      "Net Rewards",
      vegaRewards.liquidTokensNetRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Total Rewards",
      vegaRewards.liquidTokensTotalRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Validator Fee (11.7%)",
      vegaRewards.liquidTokensValidatorFee.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "CoinList Fee (15%)",
      vegaRewards.liquidTokensCoinlistFee.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "User Rewards",
      vegaRewards.liquidTokensUserRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
  ];

  const coinlistFeesRows = [
    [
      "Net Rewards",
      vegaRewards.coinlistFeesNetRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Total Rewards",
      vegaRewards.coinlistFeesTotalRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Validator Fee (11.7%)",
      vegaRewards.coinlistFeesValidatorFee.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "CoinList Fee (15%)",
      vegaRewards.coinlistFeesCoinlistFee.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "User Rewards",
      vegaRewards.coinlistFeesUserRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
  ];
  const totalRewardsRows = [
    [
      "Liquid Token Rewards",
      vegaRewards.liquidTokensUserRewards.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Locked Token Rewards",
      (
        vegaRewards.option1UserRewards + vegaRewards.option2UserRewards
      ).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Total to Undelegate",
      totalAmountToUndelegate.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "CoinList Fees",
      coinListFees.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "CoinList Non-Rebate Fees",
      coinListNonRebateFees.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "CoinList Owned Rewards",
      (
        vegaRewards.coinlistFeesUserRewards +
        vegaRewards.coinlistFeesCoinlistFee
      ).toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
    [
      "Amount to Move --> Operating Account",
      amountToMoveToOperatingAccount.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    ],
  ];

  return (
    <div>
      <div className="u-displayFlex u-alignItemsCenter">
        <Label>
          Start Date
          <TextInput
            type="date"
            defaultValue={startDate}
            onChange={(event) => setStartDate(event.target.value)}
          />
        </Label>
        <Label>
          End Date (exclusive)
          <TextInput
            type="date"
            defaultValue={endDate}
            onChange={(event) => setEndDate(event.target.value)}
          />
        </Label>
      </div>
      <div className="u-displayFlex">
        <div className="u-width100 u-displayFlex u-justifyContentFlexEnd">
          <Button
            loading={isLoading}
            size="extra-small"
            onClick={() => getVegaRewards()}
          >
            Apply Changes
          </Button>
        </div>
      </div>
      <Spacer spacing={2.5} />
      <TableWithTitle
        style="bordered"
        rows={option1Rows}
        title="Option 1 Participants"
      />
      <Spacer spacing={1} />
      <TableWithTitle
        style="bordered"
        rows={option2Rows}
        title="Option 2 Participants"
      />
      <Spacer spacing={1} />
      <TableWithTitle
        style="bordered"
        rows={liquidTokenRows}
        title="Liquid Tokens"
      />
      <Spacer spacing={1} />
      <TableWithTitle
        style="bordered"
        rows={coinlistFeesRows}
        title="CoinList Fees"
      />
      <Spacer spacing={1} />
      <TableWithTitle
        style="bordered"
        rows={totalRewardsRows}
        title="Total Rewards"
      />
    </div>
  );
}
