window.Algorand.Refund = {
  agreements: ($$, $this) =>
    class AgreementsView extends Backbone.View
      events:
        'submit form': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $form = $(event.target)
          $button = $form.find('.c-button')
          return if $button.hasClass 'c-button--loading'

          $button.addClass 'c-button--loading'
          $form.ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              $button.removeClass 'c-button--loading'
              Tooltips.formServerError($button)

    new AgreementsView el: $this
    
  agreements_one_year: ($$, $this) =>
    class AgreementsOneYearView extends Backbone.View
      events:
        'submit form': (event) ->
          event.preventDefault()
          event.stopPropagation()
          $form = $(event.target)
          $button = $form.find('.c-button')
          return if $button.hasClass 'c-button--loading'

          $button.addClass 'c-button--loading'
          $form.ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              $button.removeClass 'c-button--loading'
              Tooltips.formServerError($button)

    new AgreementsOneYearView el: $this
}
