Partials = require('../../../lib/partials')
PrettyAlert = require('../../../lib/pretty_alert')

window.Auctions.MultiSellerAuctions.Participants.Orders =
  row: ($$, $this) ->
    class RowView extends Backbone.View
      initialize: ->
        @disabledClass = @$el.data('disabled-class')

        # cancellation timer
        $timer = @$('.js-timer')
        if $timer.length > 0
          secondsToCancel = $timer.data('seconds-to-cancel')
          @_displayTimerCountdown($timer, secondsToCancel)
          setInterval =>
            @_displayTimerCountdown($timer, secondsToCancel)
            secondsToCancel -= 1
            if secondsToCancel < 0
              $timer.closest('.js-cancel-holder').hide()
          , 1000

      _displayTimerCountdown: ($timer, secondsToCancel) =>
        $timer.text("#{secondsToCancel} sec")

      events: ->
        'click .js-delete': ->
          PrettyAlert.confirm 'Are you sure you want to remove the order?', success: =>
            @$el.addClass(@disabledClass)
            @$('form').ajaxSubmit
              success: =>
                Partials.with Partials.selector('auctions/multi_seller_auctions/participants/order_form'), (partial) =>
                  partial.updateOnLedgerChange()

    new RowView el: $this
