AlgoWallet = require('../lib/algo_wallet.coffee')
Partials = require('../lib/partials')
PrettyAlert = require('../lib/pretty_alert')

Chart = require 'chart.js'
Chart.platform.disableCSSInjection = true
Chart.Tooltip.positioners.custom = (elements, eventPosition) =>
  element = elements.filter (element) -> element._datasetIndex == 4
  if element[0]
    model = element[0]._model
    {x: model.x, y: model.y}
  else
    {x: 0, y: 0}

window.Algorand.Auction = {
  auction_chart: ($$, $this) =>
    class AuctionChartView extends Backbone.View
      initialize: ->
        $chart = @$('.js-chart')
        chart_data = $chart.data('chart-data')
        return unless chart_data

        max_chart_value = parseInt($chart.data('max-chart-value'))
        num_algos = parseInt($chart.data('num-algos'))
        ctx = $chart[0].getContext("2d")
        gradientStroke = ctx.createLinearGradient(500, 0, 100, 0)
        gradientStroke.addColorStop(0, "#f49080")
        gradientStroke.addColorStop(1, "#80b6f4")
        # Hacky fix for the label position flickering the first time it is rendered
        remainingAlgosLabelYOffset = -5
        remainingAlgosYOffset = -5

        @auctionChart = new Chart $chart,
            type: 'line'
            data: chart_data
            options:
              layout:
                padding:
                  bottom: 20
                  left: 5
              animation:
                duration: 0
                onComplete: ->
                  for bid_dataset, index in [this.data.datasets[1], this.data.datasets[2], this.data.datasets[3]]
                    for data, data_index in bid_dataset.data
                      if data
                        model = bid_dataset._meta[0].data[data_index]._model
                        ctx.fillStyle = "#FFF"
                        ctx.font = 'bold 10px "Helvetica Neue", Helvetica, Arial, sans-serif'
                        ctx.fillText(data.bid_number, model.x - 3, model.y + 3)

                  last_point_dataset = this.data.datasets[0]
                  last_point_index = last_point_dataset.data.length - 1
                  last_point_value = last_point_dataset.data[last_point_index]
                  return if last_point_value == 0
                  model = last_point_dataset._meta[0].data[last_point_index]._model
                  ctx.fillStyle = "#999"
                  ctx.font = 'bold 14px "Helvetica Neue", Helvetica, Arial, sans-serif'
                  ctx.fillText('ALGOS REMAINING', model.x + 20, model.y + remainingAlgosLabelYOffset)
                  algos_percent = Math.floor((last_point_value / num_algos) * 100)
                  algos_remaining_locale_string =
                    parseFloat(last_point_value).toLocaleString(undefined, {maximumFractionDigits: 2})
                  algos_remaining_string = "#{algos_remaining_locale_string} (#{algos_percent}%)"
                  ctx.fillStyle = "#1164c5aa"
                  ctx.font = 'bold 18px "Helvetica Neue", Helvetica, Arial, sans-serif'
                  ctx.fillText(algos_remaining_string, model.x + 20, model.y + remainingAlgosYOffset + 22)
              hover:
                animationDuration: 0
              responsiveAnimationDuration: 0
              maintainAspectRatio: false
              legend:
                display: false
              tooltips:
                mode: 'index'
                intersect: false
                position: 'custom'
                filter: (tooltipItem) =>
                  tooltipItem.datasetIndex == 4
                enabled: false
                custom: (tooltipModel) =>
                  tooltipEl = document.getElementById('chartjs-tooltip')
                  lineEl = document.getElementById('chartjs-tooltip-line')
                  if (!tooltipEl)
                    tooltipEl = document.createElement('div')
                    tooltipEl.id = 'chartjs-tooltip'
                    tooltipEl.innerHTML = '<div></div>'
                    document.body.appendChild(tooltipEl)
                    lineEl = document.createElement('div')
                    lineEl.id = 'chartjs-tooltip-line'
                    lineEl.innerHTML = '<div></div>'
                    document.body.appendChild(lineEl)
                  if (tooltipModel.opacity == 0)
                    tooltipEl.style.opacity = 0
                    lineEl.style.opacity = 0
                    return
                  title = tooltipModel.title[0]
                  return if !tooltipModel.body[0]
                  body = tooltipModel.body[0].lines[0]
                  innerHtml = "#{body} remaining at #{title}"
                  tooltipEl.querySelector('div').innerHTML = innerHtml
                  position = $chart[0].getBoundingClientRect()
                  tooltipEl.style.opacity = 1
                  tooltipEl.style.position = 'absolute'
                  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
                  tooltipEl.style.top = position.bottom + window.pageYOffset - 20 + 'px'
                  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
                  tooltipEl.style.fontSize = '14px'
                  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
                  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
                  tooltipEl.style.pointerEvents = 'none'
                  lineEl.style.opacity = 1
                  lineEl.style.position = 'absolute'
                  lineEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
                  lineEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
                  lineEl.style.width = '1px'
                  lineEl.style.height = position.bottom - position.top -  tooltipModel.caretY - 10 + 'px'
                  lineEl.style.background = "#AAA"
              scales:
                xAxes: [{
                  ticks:
                    display: false
                  gridLines:
                    display: false
                }],
                yAxes: [{
                  gridLines:
                    drawBorder: false
                    zeroLineWidth: 2
                    zeroLineColor: gradientStroke
                    tickMarkLength: 0
                  ticks:
                    display: false
                    beginAtZero: true
                    max: max_chart_value
                  scaleLabel:
                    display: true
                    labelString: 'REMAINING ALGOS'
                }]

      updateBidStatus: (bidNumber, status) ->
        acceptedBids= @auctionChart.data.datasets[1]
        queuedBids = @auctionChart.data.datasets[2]
        failedBids= @auctionChart.data.datasets[3]
        currentLastPointHighlightData = @auctionChart.data.datasets[0].data
        for bidData, i in queuedBids.data
          if bidData && bidData.bid_number == bidNumber
            newBidData = {
              y: bidData.y,
              bid_number: bidData.bid_number,
              status: status
            }
            if status == 'Accepted'
              acceptedBids.data[currentLastPointHighlightData.length - 1] = newBidData
              queuedBids.data[i] = null
              @auctionChart.update()
            else if status == 'Failed'
              failedBids.data[currentLastPointHighlightData.length - 1] = newBidData
              queuedBids.data[i] = null
              @auctionChart.update()

      updateRemainingAlgos: (remainingAlgosChartData) ->
        currentLastPointHighlightData = @auctionChart.data.datasets[0].data
        newLastPointHighlightData = remainingAlgosChartData.last_point_highlight_data
        currentRemainingAlgosLineData = @auctionChart.data.datasets[4].data
        newRemainingAlgosLineData = remainingAlgosChartData.remaining_algos_line_data
        for i in [0..remainingAlgosChartData.last_point_index]
          currentLastPointHighlightData[i] = newLastPointHighlightData[i]
          currentRemainingAlgosLineData[i] = newRemainingAlgosLineData[i]
        @auctionChart.update()

    new AuctionChartView el: $this

  auction_info: ($$, $this) =>
    class AuctionInfoView extends Backbone.View
      initialize: ->
        @auctionStatus = @$el.data('auctionStatus')
        @remainingAlgos = @$el.data('remainingAlgos')
        Partials.with Partials.selector('web_sockets/algorand_bid_status'), (partial) =>
          partial.on 'update', (data) =>
            Partials.with Partials.selector('algorand/auction/auction_chart'), (partial) =>
              partial.updateBidStatus(data.bid_index, data.bid_status)

        Partials.with Partials.selector('web_sockets/algorand_auction_status'), (partial) =>
          partial.on 'update', (data) =>
            # Force a full page reload if the auction has opened or closed, or if the Algos remaining hits 0
            # for the first time
            if @auctionStatus != data.auction_status || (@remainingAlgos > 0 && data.remaining_algos <= 0)
              location.reload()
            else
              @updateAuctionStatus(data)

      events:
        'click .js-auction_info': (event) ->
          @$('.js-auction_details').show()
          $.magnificPopup.open({
            items: {
              src: @$('.js-auction_details')
              type: 'inline'
            }
          })
        'click .js-auction_nav': (event) ->
          navUrl = $(event.currentTarget).data('navUrl')
          window.location = navUrl

      updateAuctionStatus: (data) ->
        if data.auction_status == 'Announced'
          Partials.with Partials.selector('investments/shared/countdown_time'), (partial) =>
            partial.resetTime(data.estimated_start_at)
        else
          if Date.now < data.estimated_finish_at
            location.reload()
          else
            @$('.js-current_price').text(data.auction_price)
            @$('.js-dollars_committed').text(data.dollars_committed)
            Partials.with Partials.selector('algorand/auction/auction_chart'), (partial) =>
              partial.updateRemainingAlgos(data.remaining_algos_chart_data)
            Partials.with Partials.selector('investments/shared/countdown_time'), (partial) =>
              partial.resetTime(data.estimated_finish_at)

    new AuctionInfoView el: $this

  bid_module: ($$, $this) =>
    class BidModuleView extends Backbone.View
      initialize: ->
        @auctionID = @$el.data('auctionId')
        @entityID = @$el.data('entityId')
        @address = @$el.data('algorandAddress')
        @wallet = new AlgoWallet(@entityID, @address)
        @currentPrice = @$el.data('currentPrice')
        @usingCurrentPrice = true
        @remainingAlgos = @$el.data('remainingAlgos')
        @availableUsd = @$el.data('availableUsd')
        @showingConfirmation = false
        @$form = @$('form')

        # TODO: This should actually see if the mnemonic was successfully imported
        # and if it matches the address we have on file
        if @wallet.isSetup()
          @$('.js-init_success').show()
        else
          @$('.js-init_error').show()

        Partials.with Partials.selector('web_sockets/algorand_auction_status'), (partial) =>
          partial.on 'update', (data) =>
            if data.auction_status == 'Running'
              @currentPrice = data.auction_price_cents
              @remainingAlgos = data.remaining_algos
              # Only update the price and bid amount if user has not modified the price and is not on the
              # confirmation screen.
              if @usingCurrentPrice && !@showingConfirmation
                @$('.js-max_price').val(@currentPrice / 100)
                maxBidAmount = Math.floor(@currentPrice * @remainingAlgos)
                if maxBidAmount < @inputToCents(@$('.js-bid_amount').val())
                  @$('.js-bid_amount').val(maxBidAmount / 100)
                @updatePotentialAlgos()

      inputToCents: (input) ->
        Math.round(parseFloat(input.replace(/,/g, '')) * 100)

      showConfirmBid: ->
        maxPrice = @inputToCents(@$('.js-max_price').val())
        bidAmount = @inputToCents(@$('.js-bid_amount').val())
        if isNaN(maxPrice) || isNaN(bidAmount) || maxPrice <= 0 || bidAmount <= 0
          PrettyAlert.alert('Please enter a positive max price and bid amount')
          return
        if bidAmount > @availableUsd
          PrettyAlert.alert('You do not have enough available funds to make this bid')
          return
        if maxPrice > @currentPrice
          PrettyAlert.alert('Max price can not be higher than current price')
          return
        if maxPrice > @currentPrice
          PrettyAlert.alert('Max price can not be higher than current price')
          return
        if maxPrice > bidAmount
          PrettyAlert.alert('Please bid for at least 1 whole algo')
          return
        if @usingCurrentPrice || maxPrice > @currentPrice
          @$('.js-stop_order_notes').addClass('u-hidden')
        else
          @$('.js-stop_order_notes').removeClass('u-hidden')
        @$('.js-confirm_bid_amount').html('$' + @$('.js-bid_amount').val())
        @$('.js-confirm_desired_price').html('$' + @$('.js-max_price').val())
        @$('.js-confirm_potential_algos').html(@$('.js-potential_algos').html())
        @$('.js-order_form').addClass('u-hidden')
        @$('.js-confirm_order').removeClass('u-hidden')
        @showingConfirmation = true

      cancelConfirmBid: ->
        @$('.js-order_form').removeClass('u-hidden')
        @$('.js-confirm_order').addClass('u-hidden')
        @showingConfirmation = false

      submitBid: ->
        @$submit = @$form.find('input[type="submit"]')
        return if @$submit.hasClass('c-button--loading')

        # The amount of micro Algos, the native unit of the Algorand blockchain, required
        # to make one whole Algo
        wholeAlgo = 1000000

        maxPrice = @inputToCents(@$form.find('input[name="new_bid[price]"]').val())
        bidAmount = @inputToCents(@$form.find('input[name="new_bid[amount]"]').val())

        if isNaN(maxPrice) || isNaN(bidAmount) || maxPrice <= 0 || bidAmount <= 0
          PrettyAlert.alert("Please enter a positive max price and bid amount")
          @cancelConfirmBid()
          return

        if bidAmount < maxPrice
          PrettyAlert.alert("You must buy at least one whole algo, please raise your bid amount")
          @cancelConfirmBid()
          return

        if maxPrice < @$el.data('lastPrice')
          PrettyAlert.alert("Please bid a price that is above the auction's lowest price")
          @cancelConfirmBid()
          return

        # bidID is a random number between 0 and 2^31 - 1 (maximum 32-bit signed integer)
        bidID = Math.floor(Math.random() * (2**31 - 1))

        bid = {
          auctionKey: @$el.data('auctionAddress')
          bidderKey: @address
          bidID: bidID
          auctionID: @auctionID
          maxPrice: maxPrice
          bidAmount: bidAmount * wholeAlgo
        }

        @wallet.signBid bid,
          success: (signedBid) =>
            signedBinary = btoa(String.fromCharCode.apply(null, signedBid))

            @$submit.addClass('c-button--loading')

            $.ajax
              url: '/api/algorand/auctions/' + @auctionID + '/bids'
              method: 'POST'
              data:
                SignedBinary: signedBinary
                entity_id: @entityID
              success: ->
                location.reload()
              error: =>
                PrettyAlert.alert("There was an error posting that bid")
                @$submit.removeClass('c-button--loading')

      toggleUseCurrentPrice: (usingCurrentPrice) ->
        if usingCurrentPrice
          @usingCurrentPrice = true
          @$('.js-using_current_price').removeClass('u-hidden')
          @$('.js-use_current_price').addClass('u-hidden')
        else
          @usingCurrentPrice = false
          @$('.js-use_current_price').removeClass('u-hidden')
          @$('.js-using_current_price').addClass('u-hidden')

      updatePotentialAlgos: ->
        maxPrice = @inputToCents(@$('.js-max_price').val())
        bidAmount = @inputToCents(@$('.js-bid_amount').val())
        if maxPrice > 0 && bidAmount > 0
          potentialAlgos =
            Math.min((bidAmount / maxPrice), @remainingAlgos).toLocaleString(undefined, {maximumFractionDigits: 2})
          @$('.js-potential_algos').html(potentialAlgos)
        else
          @$('.js-potential_algos').html(0)

      events: ->
        'click .js-place_order': (event) ->
          event.preventDefault()
          event.stopPropagation()
          @showConfirmBid()
        'click .js-cancel_confirmation': (event) ->
          event.preventDefault()
          event.stopPropagation()
          @cancelConfirmBid()
        'submit': (event) ->
          event.preventDefault()
          event.stopPropagation()
          @submitBid()
        'input .js-max_price': (event) ->
          $target = @$(event.target)
          bid_price = @inputToCents($target.val())
          if bid_price == @currentPrice
            @toggleUseCurrentPrice(true)
          else
            @toggleUseCurrentPrice(false)
          @updatePotentialAlgos()
        'input .js-bid_amount': (event) ->
          @updatePotentialAlgos()
        'click .js-use_current_price_link': (event) ->
          event.preventDefault()
          event.stopPropagation()
          @$('.js-max_price').val(@currentPrice / 100)
          @toggleUseCurrentPrice(true)
          @updatePotentialAlgos()

    new BidModuleView el: $this

    # Return true so no one has access to this
    true

  bid_listing: ($$, $this) ->
    class BidListingView extends Backbone.View
      initialize: ->
        @auctionID = @$el.data('auctionId')
        @entityID = @$el.data('entityId')
        Partials.with Partials.selector('web_sockets/algorand_bid_status'), (partial) =>
          partial.on 'update', (data) =>
            $.ajax
              url: @$el.data('refreshPath'),
              dataType: 'JSON',
              success: (response) =>
                @$el.replaceWith response.html

        Partials.with Partials.selector('web_sockets/algorand_auction_status'), (partial) =>
          partial.on 'update', (data) =>
            @$('.js-potential-algos').each (i, element) =>
              bidAmount = $(element).data('bid')
              maxAlgos = $(element).data('maxAlgos')
              potentialAlgos = Math.min(maxAlgos, bidAmount * 100 / data.auction_price_cents)
              $(element).html(potentialAlgos.toLocaleString(undefined, {maximumFractionDigits: 2}))

      events:
        'click .js-delete_bid': (event) ->
          bidID = @$(event.currentTarget).data('bidId')
          @retractBid(bidID)

      retractBid: (bidID) ->
        PrettyAlert.confirm "Are you sure you want to delete this bid? This cannot be undone.",
          success: =>
            $.ajax
              url: '/api/algorand/auctions/' + @auctionID + '/bids/' + bidID
              method: 'DELETE'
              data:
                entity_id: @entityID
              success: ->
                location.reload()
              error: ->
                PrettyAlert.alert("That bid could not be retracted")

    new BidListingView el: $this
}
