PrettyAlert = require('../lib/pretty_alert')

window.Algorand.Wallet = {
  primetrust_consent: ($$, $this) =>
    class PrimetrustConsentView extends Backbone.View
      events:
        'click .js-accept_primetrust_terms': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          $.ajax
            url: $target.attr('href')
            type: 'POST'
            success: (response) ->
              window.location.reload()
    new PrimetrustConsentView el: $this

  withdraw_destination_form: ($$, $this) =>
    class WithdrawDestinationFormView extends Backbone.View
      initialize: ->
        @_selectCurrency()

      events:
        'change .js-currency-type': ->
          @_selectCurrency()
        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          $target.addClass 'c-button--loading'
          @$('form').ajaxSubmit
            success: (response) =>
              window.location.href = response.redirect_url
            error: (response) =>
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith($html)
                $error = $html.find('.js-error').first()
                if $error.length > 0
                  Animate.niceScrollTo($error, -120)
              else
                Tooltips.formServerError($target)

      _selectCurrency: ->
        currency = @$('input.js-currency-type:checked').val()

        $('.js-show-for-selected-currency').hide()
        $('.js-show-for-selected-currency').each (i, item) =>
          if $(item).data('currency') ==currency
            $(item).show()

    new WithdrawDestinationFormView el: $this

  withdraw_form: ($$, $this) =>
    class WithdrawFormView extends Backbone.View
      initialize: ->
        @conversionRates = @$el.data('conversion-rates')

      _inputToDollars: (input) ->
        parseFloat(input.replace(/,/g, ''))

      _showConversionRates: ->
        destination = @$('.js-withdraw-destination').val()
        conversionRate = @conversionRates[destination]
        withdrawAmount = @_inputToDollars(@$('.js-withdraw-amount').val())
        if conversionRate && withdrawAmount
          cryptoAmount = (withdrawAmount / conversionRate[0]).toLocaleString(undefined, {maximumFractionDigits: 6})
          @$('.js-conversion-rate-info').show()
          message = 'You will get ~' + cryptoAmount + ' ' + conversionRate[1].toUpperCase() +
            ' based on rates from Wyre at ' + conversionRate[2]
          @$('.js-conversion-rate-info').html(message)
        else
          @$('.js-conversion-rate-info').hide()

      events:
        'change .js-withdraw-destination': ->
          @_showConversionRates()

        'input .js-withdraw-amount': ->
          @_showConversionRates()

        'click .js-submit': (event) ->
          event.preventDefault()
          $target = $(event.target)
          return if $target.hasClass 'c-button--loading'
          PrettyAlert.confirm 'Are you sure?',
            success: =>
              $target.addClass 'c-button--loading'
              @$('form').ajaxSubmit
                success: (response) =>
                  window.location.href = response.redirect_url
                error: (response) =>
                  if response.status == 422
                    $html = $(response.responseJSON.html)
                    @$el.replaceWith($html)
                    $error = $html.find('.js-error').first()
                    if $error.length > 0
                      Animate.niceScrollTo($error, -120)
                  else
                    Tooltips.formServerError($target)

    new WithdrawFormView el: $this
}
