require('jquery-countdown')
require('moment-timezone')
Accounting = require('accounting')
BigNumber = require('bignumber.js')
Partials = require('../lib/partials')
moment = require('moment')
require('@selectize/selectize')
require('cleave.js')

{RequestForQuoteCalculations} = require "./request_for_quote_calculations"

window.QuotableIntegration.RequestsForQuote =

  # Whole partial, contains form/review/confirmation
  order: ($$, $this) ->
    class OrderView extends Backbone.View
      initialize: ->
        @QUOTED_MESSAGE_TYPE = @$el.data('quoted-message-type')
        @COMPLETED_MESSAGE_TYPE = @$el.data('completed-message-type')
        @EXPIRED_MESSAGE_TYPE = @$el.data('expired-message-type')
        @FAILURE_MESSAGE_TYPE = @$el.data('failure-message-type')
        @QUOTED_AMOUNT_EXCEEDS_BALANCE_MESSAGE_TYPE =
          @$el.data('quoted-amount-exceeds-balance-message-type')

        @SERVICE_UNAVAILABLE_ERROR_CODES = @$el.data('service-unavailable-error-codes')
        @CLIENT_INSUFFICIENT_BALANCE_TO_TRADE_ERROR_CODE =
          @$el.data('client-insufficient-balance-to-trade-error-code')
        @BLOCKED_ENTITY_ERROR_CODE = @$el.data('blocked-entity-error-code')
        @USER_HAS_UNFINISHED_RFQ_ERROR_CODE = @$el.data('user-has-unfinished-rfq-error-code')
        @currentRequestForQuoteId = null

        Backbone.history.start({pushState: true, root: @$('.js-router-data').data('root')})

        @_bindRequestForQuoteWebSocket()

        @_bindIndicativePricingWebSocket()
        @_bindCurrentRequestForQuoteIdUpdated()

        @_bindRfqSubmitError()

      hideIndicativePricesUsdPrices: () ->
        Partials.with $(Partials.selector('quotable_integration/indicative_prices/usd_prices_loading')), (partial) =>
          partial.$el.hide()

      showIndicativePricesUsdPrices: () ->
        Partials.with $(Partials.selector('quotable_integration/indicative_prices/usd_prices_loading')), (partial) =>
          partial.$el.show()

      hideOrderQuoted: ->
        Partials.with @$(Partials.selector('quotable_integration/requests_for_quote/order_quoted')), (partial) =>
          partial.stopCountdown()
          partial.clearConfirmTimeout()

        @$('.js-order-quoted').hide().html('')

      # Step 1: Order form
      showOrderForm: ->
        @currentRequestForQuoteId = null
        Partials.with @$(Partials.selector('quotable_integration/requests_for_quote/order_form')), (partial) =>
          partial.enableUi()

        @hideOrderQuoted()
        @showIndicativePricesUsdPrices()
        @$('.js-order-form-wrapper').fadeIn('fast')

      # Step 2: Order is quoted: review and approve or back
      showOrderQuoted: (orderQuotedHtml)  ->
        return if @$('.js-order-quoted').is(':visible')
        @$('.js-order-form-wrapper').hide()
        @$('.js-order-quoted').html(orderQuotedHtml).fadeIn('fast')
        @hideIndicativePricesUsdPrices()

      # Step 3: Order is completed
      showOrderCompleted: (orderCompleted)  ->
        return if @$('.js-order-completed').is(':visible')
        @hideOrderQuoted()
        @$('.js-order-completed').html(orderCompleted).fadeIn('fast')

      # Message: If MM are not available: Service unavailable message
      showServiceUnavailableMessage: ->
        @hideMessages()
        @$('.js-service-unavailable-message').fadeIn('fast')
        @scrollToTop()
        @$('.js-order-quoted, .js-order-form-wrapper').hide()

      showInsufficientBalanceToTradeErrorMessage: ->
        @hideMessages()
        @$('.js-insufficient-balance-to-trade-error-message').fadeIn('fast')
        @scrollToTop()

      showAssetUnavailableMessage: ->
        @hideMessages()
        @$('.js-asset-unavailable-message').fadeIn('fast')
        @scrollToTop()

      hideAssetUnavailableMessage: ->
        @hideMessages()
        @$('.js-asset-unavailable-message').hide()
        @scrollToTop()

      # Message: Quote expired
      showQuoteExpiredMessage: ->
        @hideMessages()
        @$('.js-quote-expired-message').fadeIn('fast')
        @scrollToTop()

      # Message: Unexpected error
      showUnexpectedErrorMessage: ->
        @hideMessages()
        @$('.js-unexpected-error-message').fadeIn('fast')
        @scrollToTop()

      # Message: quote or trade failure
      showFailureMessage: (failure) ->
        @hideMessages()
        @$('.js-failure-message').html(failure).fadeIn('fast')
        @scrollToTop()

      showAccountErrorMessage: ->
        @hideMessages()
        @$('.js-account-error-message').fadeIn('fast')
        @scrollToTop()
        @$('.js-order-quoted, .js-order-form-wrapper').hide()

      showUserUnfinishedRfqErrorMessage: ->
        @hideMessages()
        @$('.js-user-unfinished-rfq-error-message').fadeIn('fast')
        @scrollToTop()
        @$('.js-order-quoted, .js-order-form-wrapper').hide()

      # Message: Quote amount exceed entity balance
      showQuotedAmountExceedsBalanceMessage: (quotedAmountExceedsBalance)  ->
        @hideMessages()
        @$('.js-order-quoted-amount-exceeds-balance-message').html(quotedAmountExceedsBalance).fadeIn('fast')
        @scrollToTop()

      # Message: websocket connection dropped
      showWebsocketDisconnectedMessage: ->
        @hideMessages()
        @$('.js-websocket-disconnected-message').fadeIn('fast')
        @scrollToTop()

      # Message: Websocket connection (re)conneted
      showWebsocketConnectedMessage: ->
        @hideMessages()
        @$('.js-websocket-connected-message').fadeIn('fast')

        # Hide success message after 5s
        setTimeout =>
          @$('.js-websocket-connected-message').slideUp('fast')
        , 5000

        @scrollToTop()

      scrollToTop: ->
        Partials.with $(Partials.selector 'layouts/shared/market'), (partial) =>
          partial.scrollToTop()

      hideMessages: ->
        @$('.js-message').hide()

      _bindCurrentRequestForQuoteIdUpdated: ->
        Partials.with @$(Partials.selector 'quotable_integration/requests_for_quote/order_form'), (order_form) =>
          order_form.on 'currentRequestForQuoteIdUpdated', (currentRequestForQuoteId) =>
            @currentRequestForQuoteId = currentRequestForQuoteId

      _bindRfqSubmitError: ->
        Partials.with @$(Partials.selector 'quotable_integration/requests_for_quote/order_form'), (order_form) =>
          order_form.on 'rfqSubmitError', (rfqSubmitErrorCode) =>
            if rfqSubmitErrorCode in @SERVICE_UNAVAILABLE_ERROR_CODES
              @showServiceUnavailableMessage()
            else if rfqSubmitErrorCode == @CLIENT_INSUFFICIENT_BALANCE_TO_TRADE_ERROR_CODE
              @showInsufficientBalanceToTradeErrorMessage()
            else if rfqSubmitErrorCode == @BLOCKED_ENTITY_ERROR_CODE
              @showAccountErrorMessage()
            else if rfqSubmitErrorCode == @USER_HAS_UNFINISHED_RFQ_ERROR_CODE
              @showUserUnfinishedRfqErrorMessage()
            else
              @showUnexpectedErrorMessage()
              Rollbar.error("RFQ: unexpected error code in API response ", {rfqSubmitErrorCode: rfqSubmitErrorCode})

# Websocket - indicative pricing
      _bindIndicativePricingWebSocket: ->
        Partials.with @$(Partials.selector 'web_sockets/rfq/indicative_pricing'), (partial) =>
          partial.on 'update', (message) =>
            Partials.with @$(Partials.selector 'quotable_integration/requests_for_quote/order_form'), (partial) =>
              partial.indicativePricingStore.setPricesFromHash(message)
              partial.renderIndicativePrice()

      # Websocket - request for quote
      _bindRequestForQuoteWebSocket: ->
        Partials.with @$(Partials.selector 'web_sockets/rfq/requests_for_quote'), (websocket) =>

          websocket.on 'disconnected', () =>
            @showWebsocketDisconnectedMessage()
            Partials.with @$(Partials.selector('quotable_integration/requests_for_quote/order_form')), (partial) =>
              partial.disableUi()

          websocket.on 'connected', () =>
            # We only show the (re)connected message if we were previously disconnected!
            if @$('.js-websocket-disconnected-message').is(':visible')
              @showWebsocketConnectedMessage()
              Partials.with @$(Partials.selector('quotable_integration/requests_for_quote/order_form')), (partial) =>
                partial.enableUi()

          websocket.on 'update', (message) =>
            if @currentRequestForQuoteId == message.request_for_quote.id
              messageType = message.message_type
              switch messageType
                when @QUOTED_MESSAGE_TYPE
                  @hideMessages()
                  @showOrderQuoted(message.html)
                when @COMPLETED_MESSAGE_TYPE
                  @hideMessages()
                  @showOrderCompleted(message.html)
                when @EXPIRED_MESSAGE_TYPE && @$('.js-order-quoted').is(":visible")
                  @showOrderForm()
                  @showQuoteExpiredMessage()
                when @FAILURE_MESSAGE_TYPE
                  @showOrderForm()
                  @showFailureMessage(message.html)
                when @QUOTED_AMOUNT_EXCEEDS_BALANCE_MESSAGE_TYPE
                  @showOrderForm()
                  @showQuotedAmountExceedsBalanceMessage(message.html)
                else
                  Rollbar.warn("RFQ: unexpected message type over websocket", message: message)

      events:
        # Sell/Buy switch
        'click .js-side-selector': (event) ->
          event.preventDefault()
          $target = @$(event.target)
          side = $target.data('side')
          @$('.js-side-selector').removeClass('selected')
          $target.addClass('selected')
          Partials.with @$(Partials.selector 'quotable_integration/requests_for_quote/order_form'), (partial) =>
            partial.updateSide(side)

    new OrderView el: $this


  order_form: ($$, $this) ->
    class IndicativePricingStore
      constructor: ->
        @pricesBySymbol = {}

      setPricesFromHash: (hash) ->
        @pricesBySymbol = hash

      setPrice: (symbol, bid, offer) ->
        @pricesBySymbol[symbol] = {bid_price: bid, offer_price: offer}

      getBid: (baseAssetSymbol, counterAssetSymbol) ->
        if (@pricesBySymbol[baseAssetSymbol]?[counterAssetSymbol]?)
          return @pricesBySymbol[baseAssetSymbol]?[counterAssetSymbol]?.bid_price
        else if (@pricesBySymbol[counterAssetSymbol]?[baseAssetSymbol]?)
          return 1 / @pricesBySymbol[counterAssetSymbol]?[baseAssetSymbol]?.offer_price

      getOffer: (baseAssetSymbol, counterAssetSymbol) ->
        if (@pricesBySymbol[baseAssetSymbol]?[counterAssetSymbol]?)
          return @pricesBySymbol[baseAssetSymbol]?[counterAssetSymbol]?.offer_price
        else if (@pricesBySymbol[counterAssetSymbol]?[baseAssetSymbol]?)
          return 1 / @pricesBySymbol[counterAssetSymbol]?[baseAssetSymbol]?.bid_price

    class AssetInfoStore
      FORMAT_USD_TO_DECIMAL_PLACES: 2
      USD: 'USD'

      constructor: ->
        @assetInfo = {}

      setAssetInfoFromHash: (hash) ->
        @assetInfo = hash

      # Exact name for display, e.g. BTC = Bitcoin
      getAssetName: (assetSymbol) ->
        @assetInfo[assetSymbol].name

      getTradingEnabled: (assetSymbol) ->
        @assetInfo[assetSymbol].trading_enabled

      # Exact minimum notional, e.g: 0.00001000 BTC
      getAssetMinimumNotional: (assetSymbol) ->
        @assetInfo[assetSymbol].minimum_notional

      # Formatted minimum notional, e.g: 0.00001 BTC
      getFormattedAssetMinimumNotional: (assetSymbol) ->
        @getFormattedAssetAmount(@getAssetMinimumNotional(assetSymbol), assetSymbol)

      # Exact maximum notional, e.g: 1.5000000 BTC
      getAssetMaximumNotional: (assetSymbol) ->
        @assetInfo[assetSymbol].maximum_notional

      # Formatted maximum notional, e.g: 1.5 BTC
      getFormattedAssetMaximumNotional: (assetSymbol) ->
        @getFormattedAssetAmount(@getAssetMaximumNotional(assetSymbol), assetSymbol)

      getAssetMinimumIncrement: (assetSymbol) ->
        @assetInfo[assetSymbol].minimum_increment

      # Will get decimal place from minimum_increment, e.g: 1.53650000 BTC = 8 decimalPlaces
      getAssetdecimalPlaces: (assetSymbol) ->
        minimumIncrement = @getAssetMinimumIncrement(assetSymbol)
        NumberHelper.decimalPlaces(minimumIncrement)

      # Will get exact amount, e.g: 1.53650000 BTC
      getAssetAmount: (amount, assetSymbol) ->
        if isNaN(amount) then 0 else amount

        # Figure out maximum decimal place allowed
        decimalPlaces = @getAssetdecimalPlaces(assetSymbol)

        # Get the amount rounded by decimalPlaces
        NumberHelper.floor(amount, decimalPlaces)

      # Will get display amount, e.g: 1.5365 BTC
      getFormattedAssetAmount: (amount, assetSymbol) ->
        # Get the amount rounded by minimumIncrement
        assetAmount = @getAssetAmount(amount, assetSymbol)

        # Find out the decimal count for the exact amount
        roundedAmountdecimalPlaces = NumberHelper.decimalPlaces(assetAmount)
        if assetSymbol.includes(@USD)
          return Accounting.toFixed(assetAmount, @FORMAT_USD_TO_DECIMAL_PLACES)

        # User Accounting to format the number nicely with correct decimal count
        Accounting.formatNumber(assetAmount, roundedAmountdecimalPlaces)

    class OrderForm extends Backbone.View
      initialize: ->

        # Cache $selectors
        @$reviewOrderButton = @$('.js-review-order-button')
        @$baseAssetSymbolLabel = @$('.js-base-asset-symbol-label')
        @$counterAssetSymbolLabel = @$('.js-counter-asset-symbol-label')

        # Variables - Data
        @buyAssetPairsHash = @$el.data('buy-asset-pairs-hash')
        @sellAssetPairsHash = @$el.data('sell-asset-pairs-hash')
        @tradingFeesHash = @$el.data('trading-fees-hash')

        @balances = @$el.data('balances')

        # Variable - entities
        @entitiesSlugByIds = @$el.data('entities-slug-by-ids')

        # Used to remember amounts went toggling the primary asset
        @cachedAmounts = {}

        # Variables - constants
        @BUY = @$el.data('buy')
        @SELL = @$el.data('sell')
        @BASE = @$el.data('base')
        @COUNTER = @$el.data('counter')
        @USD_STABLECOIN_SYMBOLS = @$el.data('usdStablecoinSymbols')
        @USD_ASSET_SYMBOL = @$el.data('usdAssetSymbol')
        @ASSET_SYMBOL_HTML_DATA = @$el.data('assetSymbolHtmlData')

        @USD_ASSET_SYMBOL_FOR_DISPLAY = @$el.data('usdAssetSymbolForDisplay')
        @CURRENCY_HOLDER_USD_ASSET_KLASS = @$el.data('currencyHolderUsdAssetKlass')

        @CONTACT_LINK = @$el.data('contactLink')

        @RECURRING_TRADE_ASSET_SYMBOLS = [
          'USD',
          'BTC',
          'ETH'
        ]

        # IndicativePricing view helper
        @indicativePricingStore = new IndicativePricingStore
        @indicativePricingStore.setPricesFromHash(@$el.data('indicative-pricing'))

        # AssetInfo view helper
        @assetInfoStore = new AssetInfoStore
        @assetInfoStore.setAssetInfoFromHash(@$el.data('asset-info'))

        @renderIndicativePrice()

        # Get the UI Ready
        @_initializeSelectize()
        @_formatCleave()
        @_renderOptions()
        @enableUi()
        @_updateRouter()
        @_validateOrder()

        baseAssetSymbol = @_getBaseAssetSymbol()
        counterAssetSymbol = @_getCounterAssetSymbol()
        feature_enabled_for_base = @assetInfoStore.getTradingEnabled(baseAssetSymbol)
        feature_enabled_for_counter = @assetInfoStore.getTradingEnabled(counterAssetSymbol)
        if feature_enabled_for_counter && feature_enabled_for_base
          @enableForm()
        else
          @disableForm()

      # Update the buy/sell side
      updateSide: (side) ->
        @$('.js-inputs-wrapper').removeClass("quotable_integration-requests_for_quote-order_form__inputs-wrapper--#{@_getSide()}")
        @$('.js-side-input').val(side)
        @$('.js-inputs-wrapper').addClass("quotable_integration-requests_for_quote-order_form__inputs-wrapper--#{@_getSide()}")
        @_emptyAmountInputs()
        @_renderOptions()
        @_updateSymbols()
        @_renderLabels()
        @_updateRouter()

      _getBaseAssetMinimumNotional: ->
        @assetInfoStore.getAssetMinimumNotional(@_getBaseAssetSymbol())

      _getBaseAssetMaximumNotional: ->
        @assetInfoStore.getAssetMaximumNotional(@_getBaseAssetSymbol())

      # Counter asset minimum notional is relative to the base asset minimum notional
      _getCounterAssetMinimumNotional: ->
        @assetInfoStore.getAssetMinimumNotional(@_getCounterAssetSymbol())

      _getCounterAssetMaximumNotional: ->
        @assetInfoStore.getAssetMaximumNotional(@_getCounterAssetSymbol())

      _getPrimaryAssetMinimumNotional: ->
        if @_primaryAssetIsBaseAsset()
          @_getBaseAssetMinimumNotional()
        else
          @_getCounterAssetMinimumNotional()

      _getPrimaryAssetMaximumNotional: ->
        if @_primaryAssetIsBaseAsset()
          @_getBaseAssetMaximumNotional()
        else
          @_getCounterAssetMaximumNotional()

      _getSecondaryAssetMinimumNotional: ->
        if @_primaryAssetIsBaseAsset()
          @_getCounterAssetMinimumNotional()
        else
          @_getBaseAssetMinimumNotional()

      _getSecondaryAssetMaximumNotional: ->
        if @_primaryAssetIsBaseAsset()
          @_getCounterAssetMaximumNotional()
        else
          @_getBaseAssetMaximumNotional()

      _getSide: ->
        @$('.js-side-input').val()

      _getEntityId: ->
        @$('.js-entity-select-input').val()

      _getEntitySlug: ->
        @entitiesSlugByIds[@_getEntityId()]

      _getBaseAssetSymbol: ->
        @$('.js-base-asset-symbol-input').val()

      _getCounterAssetSymbol: ->
        @$('.js-counter-asset-symbol-input').val()

      _getPrimaryAssetSymbol: ->
        @$('.js-primary-asset-symbol-input').val()

      _getSymbolForAssetReceivedByUser: ->
        if @_getSide() == @BUY
          @_getBaseAssetSymbol()
        else
          @_getCounterAssetSymbol()

      _getPairTicker: ->
        @_getBaseAssetSymbol() + "-" + @_getCounterAssetSymbol()

      _getSymbolForAssetGivenUpByUser: ->
        if @_getSide() == @BUY
          @_getCounterAssetSymbol()
        else
          @_getBaseAssetSymbol()

      _getCurrentBalanceForAssetUserIsSelling: ->
        new BigNumber(@balances[@_getSymbolForAssetGivenUpByUser()])

      _getSecondaryAssetSymbol: ->
        if @_primaryAssetIsBaseAsset()
          @_getCounterAssetSymbol()
        else if @_primaryAssetIsCounterAsset()
          @_getBaseAssetSymbol()

      _getPrimaryAssetAmount: ->
        amount = parseFloat(@cleave.getRawValue())
        if isNaN(amount) then 0.0 else amount

      _getFeeRate: ->
        parseFloat(@tradingFeesHash[@_getPairTicker()]["fee_rate"])

      _getFeeMinimum: ->
        parseFloat(@tradingFeesHash[@_getPairTicker()]["fee_minimum"])

      _getRfqCalculations: ->
        new RequestForQuoteCalculations(@_getSide(),
                                        @_getBaseAssetSymbol(),
                                        @_getCounterAssetSymbol(),
                                        @_getFeeRate(),
                                        @_getFeeMinimum(),
                                        @_getPrimaryAssetSymbol(),
                                        @_getPrimaryAssetAmount(),
                                        @_getIndicativePrice())

      _getIndicativePrice: ->
        if @_getSide() == @BUY
          @indicativePricingStore.getOffer(@_getBaseAssetSymbol(), @_getCounterAssetSymbol())
        else if @_getSide() == @SELL
          @indicativePricingStore.getBid(@_getBaseAssetSymbol(), @_getCounterAssetSymbol())

      _getAssetPairsHash: ->
        if @_getSide() == @BUY
          @buyAssetPairsHash
        else if @_getSide() == @SELL
          @sellAssetPairsHash

      _primaryAssetIsBaseAsset: ->
        @_getPrimaryAssetSymbol() == @_getBaseAssetSymbol()

      _primaryAssetIsCounterAsset: ->
        @_getPrimaryAssetSymbol() == @_getCounterAssetSymbol()

      # 2.41 BTC or $100.43
      _getFormattedAssetAmountAndSymbol: (amount, symbol) ->
        symbolForDisplay = @_symbolForDisplay(symbol)
        formattedAmount = @assetInfoStore.getFormattedAssetAmount(amount, symbol)
        if symbol == @USD_ASSET_SYMBOL
          "#{symbolForDisplay}#{formattedAmount}"
        else
          "#{formattedAmount} #{symbolForDisplay}"

      _symbolForDisplay: (symbol) ->
        if symbol == @USD_ASSET_SYMBOL then @USD_ASSET_SYMBOL_FOR_DISPLAY else symbol

      _renderPrimaryAssetSymbol: ->
        @$('.js-primary-asset-symbol').text(@_symbolForDisplay(@_getPrimaryAssetSymbol()))

      _renderSecondaryAssetSymbolAndAmount: ->
        symbolAndAmount =
          @_getFormattedAssetAmountAndSymbol(@_getRfqCalculations().getSecondaryAssetAmount(),
                                             @_getSecondaryAssetSymbol())
        @$('.js-secondary-asset').text(symbolAndAmount)
        @$('.js-secondary-asset-symbol').text(@_symbolForDisplay(@_getSecondaryAssetSymbol()))

      _assetPairIncludesUsd: ->
        @_getBaseAssetSymbol() == @USD_ASSET_SYMBOL ||
          @_getCounterAssetSymbol() == @USD_ASSET_SYMBOL

      _assetPairIncludesUsdStablecoin: ->
        _.includes(@USD_STABLECOIN_SYMBOLS, @_getBaseAssetSymbol()) ||
          _.includes(@USD_STABLECOIN_SYMBOLS, @_getCounterAssetSymbol())

      _toggleUsdDisplay: ->
        primaryAssetIsUsd = @_getPrimaryAssetSymbol() == @USD_ASSET_SYMBOL
        @$('.js-currency-holder').toggleClass(@CURRENCY_HOLDER_USD_ASSET_KLASS, primaryAssetIsUsd)

      _renderBaseAssetSymbol: (symbol) ->
        @$('.js-base-asset-symbol').text(symbol)

      _renderCounterAssetSymbol: (symbol) ->
        @$('.js-counter-asset-symbol').text(symbol)

      renderIndicativePrice: ->
        formattedAmount =
          @assetInfoStore.getFormattedAssetAmount(@_getIndicativePrice(), @_getCounterAssetSymbol())
        @$('.js-indicative-price').text(formattedAmount)

      _updateRouter: ->
        Backbone.history.navigate("#{@_getEntitySlug()}/trade/#{@_getSide()}/#{@_getBaseAssetSymbol().toLowerCase()}/#{@_getCounterAssetSymbol().toLowerCase()}?entity_id=#{@_getEntityId()}")

      # Buy/Sell For/With
      _renderLabels: ->
        if @_getSide() == @SELL
          @$baseAssetSymbolLabel.html(@$baseAssetSymbolLabel.data('sellLabel'))
          @$counterAssetSymbolLabel.html(@$counterAssetSymbolLabel.data('sellLabel'))
        else
          @$baseAssetSymbolLabel.html(@$baseAssetSymbolLabel.data('buyLabel'))
          @$counterAssetSymbolLabel.html(@$counterAssetSymbolLabel.data('buyLabel'))

      _initializeSelectize: ->
        selectizeOptions =
          _.extend(SelectizePreset.renderHtml(@ASSET_SYMBOL_HTML_DATA), {
            maxItems: 1,
            sortField	: [{field: 'value', direction: 'asc'}],
            hideSelected: true
          })

        $baseSelect = @$('.js-base-asset-symbol-input').selectize(selectizeOptions)
        @baseSelectize = $baseSelect[0].selectize

        $counterSelect = @$('.js-counter-asset-symbol-input').selectize(selectizeOptions)
        @counterSelectize = $counterSelect[0].selectize

        # Render base option
        _.each Object.keys(@_getAssetPairsHash()), (symbol) =>
          @baseSelectize.addOption({value: symbol})

      # Swap the base/counter inputs based on the side (buy or sell)
      _renderOptions: ->
        baseAssetSymbol = @_getBaseAssetSymbol()
        counterAssetSymbol = @_getCounterAssetSymbol()

        assetPairsHash = @_getAssetPairsHash()

        # Get a default baseAssetSymbol if needed. For example if we are switching from selling 
        # to buying a pair where the baseAsset is geofenced
        unless _.includes(Object.keys(assetPairsHash), baseAssetSymbol)
          @baseSelectize.removeItem(baseAssetSymbol, true)
          baseAssetSymbol = Object.keys(assetPairsHash)[0]

        # Get a default counterAssetSymbol if needed. We may need a default counterAssetSymbol if
        # switching from a pair where the previous counterAsset doesn't exist for the new baseAsset.
        #   e.g. buy ETH with BTC => buy BTC with ???
        unless _.includes(assetPairsHash[baseAssetSymbol], counterAssetSymbol)
          # remove exsiting selection. true stand for silent, ie: no change event is fired
          @counterSelectize.removeItem(counterAssetSymbol, true)
          counterAssetSymbol = assetPairsHash[baseAssetSymbol][0]

        # Remove all options from counter and base symbol inputs
        @baseSelectize.clearOptions(true)
        @counterSelectize.clearOptions(true)

        # add options
        _.each Object.keys(assetPairsHash), (symbol) =>
          @baseSelectize.addOption({value: symbol})
        _.each assetPairsHash[baseAssetSymbol], (symbol) =>
          @counterSelectize.addOption({value: symbol})

        @baseSelectize.addItem(baseAssetSymbol, true)
        @counterSelectize.addItem(counterAssetSymbol, true)

        # Refresh the dropdown
        @baseSelectize.refreshOptions(false)
        @counterSelectize.refreshOptions(false)

        # clear focus to update styling
        @baseSelectize.blur()
        @counterSelectize.blur()

        # Update the primary asset symbol
        @$('.js-primary-asset-symbol-input').val(counterAssetSymbol)

        feature_enabled_for_base = @assetInfoStore.getTradingEnabled(baseAssetSymbol)
        feature_enabled_for_counter = @assetInfoStore.getTradingEnabled(counterAssetSymbol)
        if feature_enabled_for_counter && feature_enabled_for_base
          @enableForm()
        else
          @disableForm()

      _setPrimaryAssetSymbolToAssetGivenUpByUser:->
        @$('.js-primary-asset-symbol-input').val(@_getSymbolForAssetGivenUpByUser())


      _setMaxAmount: ->
        # Max is either the base asset max notional amount or the user's max balance
        # TODO: This is unreadable, can it be extrapolated (e.g: BalanceStore class)
        currentBalance = @_getCurrentBalanceForAssetUserIsSelling()
        maxAmount =
          if @_getSide() == @BUY
            BigNumber.minimum(currentBalance, @_maximumCounterAssetAmountAfterFees())
          else if @_getSide() == @SELL
            BigNumber.minimum(currentBalance, @_getBaseAssetMaximumNotional())

        assetSymbol = @_getSymbolForAssetGivenUpByUser()
        decimalPlaces = @assetInfoStore.getAssetdecimalPlaces(assetSymbol)
        formattedAssetAmount = 
          maxAmount.decimalPlaces(decimalPlaces, BigNumber.ROUND_FLOOR).toFixed()

        @cleave.setRawValue(formattedAssetAmount)

      # Empty the amount inputs
      _emptyAmountInputs: ->
        @$('.js-primary-asset-amount-input').val('')
        @_resetCachedAmounts()
        @_validateOrder()

      _updateSymbols: ->
        # Get the value from input and update cached variable
        @_renderBaseAssetSymbol(@_getBaseAssetSymbol())
        @_renderCounterAssetSymbol(@_getCounterAssetSymbol())

        # If the primary asset doesn't match any of the current selected options, reset to counter
        unless @_primaryAssetIsBaseAsset() || @_primaryAssetIsCounterAsset()
          @$('.js-primary-asset-symbol-input').val(@_getCounterAssetSymbol())

        @_renderPrimaryAssetSymbol()
        @_renderSecondaryAssetSymbolAndAmount()
        @_toggleUsdDisplay()
        @renderIndicativePrice()

      _validateOrder: ->
        @$reviewOrderButton.addClass('c-button--disabled')
        @_clearErrors()

        valid = false
        if @_getSide() == @BUY
          valid = @_validateBuyOrder()
        else if @_getSide() == @SELL
          valid = @_validateSellOrder()
        else
          Rollbar.error("TradeIndex: Unexpected order side", side: @_getSide())

        if valid
          @$reviewOrderButton.removeClass('c-button--disabled')

      _clearErrors: ->
        @$('.js-primary-asset-amount-wrapper').removeClass('c-input-group--has-error')
        @$('.js-primary-asset-error').hide()

      _validateOrderAmounts: (primaryAssetSymbol, secondaryAssetSymbol) ->
        return false if @_getPrimaryAssetAmount() == 0

        # the counter asset amount sent to the market maker is different to the user specified
        # counter asset amount by an amount equal to the fee. for example if the user enters the
        # order: BTC-USD BUY primaryAsset=USD (i.e. specifies USD) = $5 then we actual send $4.75 to
        # the market maker (after accounting for the $0.25 fee that CL takes). the market maker can
        # rightly reject to quote on a $4.75 rfq as it is below the advertised minimum amount of $5.
        # so we enforce the minimum such that after accounting for fees, the amount sent to the
        # market maker is compatible with the minimum amount
        if @_primaryAssetIsCounterAsset()
          primaryAssetAmountForNotionalSizeChecks =
            @_getRfqCalculations().getMarketMakerCounterAssetAmount(@_getPrimaryAssetAmount())
        else
          primaryAssetAmountForNotionalSizeChecks = @_getPrimaryAssetAmount()

        # Below minimum allowed
        if primaryAssetAmountForNotionalSizeChecks < @_getPrimaryAssetMinimumNotional()
          @_showInputError(@_errorPrimaryMinimumNotionalMessage())
          return false

        # Over maximum allowed
        if primaryAssetAmountForNotionalSizeChecks > @_getPrimaryAssetMaximumNotional()
          @_showInputError(@_errorPrimaryMaximumNotionalMessage())
          return false

        sellAssetAmount = @_getRfqCalculations().calculateSellAssetAmount()
        if @_getCurrentBalanceForAssetUserIsSelling().isLessThan(sellAssetAmount)
          @_showInputError(@_errorInsufficientBalanceMessage(primaryAssetSymbol))
          return false

        # Secondary asset checks
        secondaryAssetAmountForNotionalSizeChecks = @_getRfqCalculations().getSecondaryAssetAmount()

        if secondaryAssetAmountForNotionalSizeChecks < @_getSecondaryAssetMinimumNotional()
          @_showInputError(@_errorSecondaryMinimumNotionalMessage())
          return false

        if secondaryAssetAmountForNotionalSizeChecks > @_getSecondaryAssetMaximumNotional()
          @_showInputError(@_errorSecondaryMaximumNotionalMessage())
          return false

        true

      _validateSellOrder: ->
        @_validateOrderAmounts(@_getBaseAssetSymbol())

      _validateBuyOrder: ->
        @_validateOrderAmounts(@_getCounterAssetSymbol())

      _showInputError: (errorMessage) ->
        @$('.js-primary-asset-error').html(errorMessage).show()
        @$('.js-primary-asset-amount-wrapper').addClass('c-input-group--has-error')

      _errorInsufficientBalanceMessage: (assetSymbol) ->
        message = "Insufficient funds in #{assetSymbol} wallet"
        if !@_coinlistFeeIsWaived() && @_getCurrentBalanceForAssetUserIsSelling().isGreaterThan(0)
          message+= " (after assessing fees)"
        walletUrl = document.getElementById('wallet-url').getAttribute('link-to-url')
        "#{message}. <a href=\"#{walletUrl}\" onclick=\"gtag('event', 'Deposit Insufficient Funds Clicked', { 'event_category': 'All' }); return true;\">Deposit additional funds</a> to continue."

      _coinlistFeeIsWaived: ->
        @_getFeeMinimum() == 0.0 &&  @_getFeeRate() == 0.0

      _minimumCounterAssetAmountAfterFees: ()->
        @_getRfqCalculations().getMinimumCounterAssetAmountAfterFees(
          parseFloat(@_getPrimaryAssetMinimumNotional())
        )

      _errorPrimaryMinimumNotionalMessage: () ->
        if @_primaryAssetIsBaseAsset()
          minimumNotionalAmount = @_getPrimaryAssetMinimumNotional()
          afterFeesString = ''
        else
          minimumNotionalAmount = @_minimumCounterAssetAmountAfterFees()
          afterFeesString = 'after fees '

        formattedAmount =
          @assetInfoStore.getFormattedAssetAmount(minimumNotionalAmount, @_getPrimaryAssetSymbol())

        "The minimum amount required to trade " + afterFeesString + "is #{formattedAmount}
        #{@_getPrimaryAssetSymbol()}. Please enter a larger amount."

      _errorSecondaryMinimumNotionalMessage: () ->
        formattedAmount =
          @assetInfoStore.getFormattedAssetAmount(@_getSecondaryAssetMinimumNotional(),
                                                  @_getSecondaryAssetSymbol())

        "The minimum amount required to trade is #{formattedAmount}
        #{@_getSecondaryAssetSymbol()}. Please enter a larger amount."

      _maximumCounterAssetAmountAfterFees: ()->
        return @_getRfqCalculations().getMaximumCounterAssetAmountAfterFees(
          parseFloat(@_getPrimaryAssetMaximumNotional())
        )

      _errorPrimaryMaximumNotionalMessage: () ->
        if @_primaryAssetIsBaseAsset()
          maximumNotionalAmount = @_getPrimaryAssetMaximumNotional()
          afterFeesString = ''
        else
          maximumNotionalAmount = @_maximumCounterAssetAmountAfterFees()
          afterFeesString = 'after fees '

        formattedAmount =
            @assetInfoStore.getFormattedAssetAmount(maximumNotionalAmount,
                                                    @_getPrimaryAssetSymbol())

        "The maximum amount is #{formattedAmount} #{@_getPrimaryAssetSymbol()}
        #{afterFeesString}. Please enter a smaller amount. #{@CONTACT_LINK} to place a
        larger order."

      _errorSecondaryMaximumNotionalMessage: () ->
        formattedAmount =
            @assetInfoStore.getFormattedAssetAmount(@_getSecondaryAssetMaximumNotional(),
                                                    @_getSecondaryAssetSymbol())

        "The maximum amount is #{formattedAmount} #{@_getSecondaryAssetSymbol()}.
        Please enter a smaller amount. #{@CONTACT_LINK} to place a larger order."

      _togglePrimaryAsset: ->
        # save this before we toggle symbols (otherwise amount will be different)
        currentSecondaryAssetAmount =
          if @cachedAmounts? && @cachedAmounts[@_getSecondaryAssetSymbol()]?
            @cachedAmounts[@_getSecondaryAssetSymbol()]
          else
            @_getRfqCalculations().getSecondaryAssetAmount()

        # Toggle symbol
        @$('.js-primary-asset-symbol-input').val(@_getSecondaryAssetSymbol())

        # Format input accordingly
        @_formatCleave(currentSecondaryAssetAmount)


      _formatCleave: (amount) ->
        # Remove previous instance
        @cleave.destroy() if @cleave?

        # get decimal place for primary asset
        decimalPlaces = @assetInfoStore.getAssetdecimalPlaces(@_getPrimaryAssetSymbol())

        # Instantiate/Format cleave
        @cleave = new Cleave('.js-primary-asset-amount-input',
                             numeral: true
                             numeralThousandsGroupStyle: 'thousand'
                             numeralDecimalScale: decimalPlaces)

        # set amount if provided
        @cleave.setRawValue(amount) if amount?

      _submitForm: ->
        @$reviewOrderButton.addClass('c-button--loading')
        @$('.js-submit-order-form').ajaxSubmit
          beforeSubmit: => @disableUi()
          success: (response) =>
            @disableUi()
            @trigger 'currentRequestForQuoteIdUpdated', response.request_for_quote_id
          error: (response) =>
            @enableUi()
            rfqSubmitErrorCode = response?.responseJSON?.error_code
            @trigger 'rfqSubmitError', rfqSubmitErrorCode
            @$reviewOrderButton.removeClass('c-button--loading')

      disableUi: ->
        @uiIsEnabled = false
        @$reviewOrderButton.addClass('c-button--disabled')
        @$('.js-set-max-amount').addClass('c-link--disabled')
        @$('.js-side-selector').addClass('c-link--disabled')
        @$('.js-primary-asset-toggle').addClass('c-link--disabled')
        @$('.js-base-asset-symbol-input').prop('disabled', true)
        @$('.js-counter-asset-symbol-input').prop('disabled', true)
        @$('.js-primary-asset-amount-input').prop('disabled', true)
        @counterSelectize.lock()
        @counterSelectize.disable()
        @baseSelectize.lock()
        @baseSelectize.disable()
        @$('.js-entity-select-input').prop('disabled', true)

      enableUi: ->
        @uiIsEnabled = true
        @$reviewOrderButton.removeClass('c-button--disabled')
        @$reviewOrderButton.removeClass('c-button--loading')
        @$('.js-set-max-amount').removeClass('c-link--disabled')
        @$('.js-side-selector').removeClass('c-link--disabled')
        @$('.js-primary-asset-toggle').removeClass('c-link--disabled')
        @$('.js-base-asset-symbol-input').prop('disabled', false)
        @$('.js-counter-asset-symbol-input').prop('disabled', false)
        @$('.js-primary-asset-amount-input').prop('disabled', false)
        @counterSelectize.unlock()
        @counterSelectize.enable()
        @baseSelectize.unlock()
        @baseSelectize.enable()
        @$('.js-entity-select-input').prop('disabled', false)

      disableForm: ->
        @formIsEnabled = false
        @$reviewOrderButton.addClass('c-button--disabled')
        @$('.js-set-max-amount').addClass('c-link--disabled')
        @$('.js-primary-asset-toggle').addClass('c-link--disabled')
        @$('.js-primary-asset-amount-input').prop('disabled', true)
        @$('.js-edit-frequency-form').addClass('u-hidden')
        Partials.with $(
          Partials.selector 'quotable_integration/requests_for_quote/order'), (partial) =>
            partial.showAssetUnavailableMessage()

      enableForm: ->
        @formIsEnabled = true
        @$reviewOrderButton.removeClass('c-button--disabled')
        @$reviewOrderButton.removeClass('c-button--loading')
        @$('.js-set-max-amount').removeClass('c-link--disabled')
        @$('.js-primary-asset-toggle').removeClass('c-link--disabled')
        @$('.js-primary-asset-amount-input').prop('disabled', false)
        @$('.js-edit-frequency-form').removeClass('u-hidden')
        Partials.with $(
          Partials.selector 'quotable_integration/requests_for_quote/order'), (partial) =>
            partial.hideAssetUnavailableMessage()

      _saveCachedAmount: ->
        @cachedAmounts[@_getPrimaryAssetSymbol()] = @_getPrimaryAssetAmount()

      _resetCachedAmounts: ->
        @cachedAmounts = {}

      _toggleFrequency: ->
        showFrequencyForm = @RECURRING_TRADE_ASSET_SYMBOLS.includes(@_getBaseAssetSymbol()) &&
          @RECURRING_TRADE_ASSET_SYMBOLS.includes(@_getCounterAssetSymbol())

        if showFrequencyForm
          @$('.js-edit-frequency-form').removeClass('u-hidden')
        else
          @$('.js-edit-frequency-form').addClass('u-hidden')

      events:
        'submit .js-submit-order-form': (e) ->
          e.preventDefault()

        # We do click .button instead of submit .form to avoid unententional enter key press
        'click .js-review-order-button': (e) ->
          e.preventDefault()
          return unless @uiIsEnabled && @formIsEnabled
          @_submitForm()

        'change .js-base-asset-symbol-input': (e) ->
          @_resetCachedAmounts()
          @_renderOptions()
          @_clearErrors()
          @_emptyAmountInputs()
          @_updateSymbols()
          @_updateRouter()
          @_formatCleave()
          @_toggleFrequency()

        'change .js-counter-asset-symbol-input': (e) ->
          @_resetCachedAmounts()
          @_renderOptions()
          @_clearErrors()
          @_emptyAmountInputs()
          @_updateSymbols()
          @_updateRouter()
          @_formatCleave()
          @_toggleFrequency()

        'click .js-set-max-amount': (e) ->
          e.preventDefault()
          return unless @uiIsEnabled && @formIsEnabled
          @_resetCachedAmounts()
          @_setPrimaryAssetSymbolToAssetGivenUpByUser()
          @_formatCleave()
          @_setMaxAmount()
          @_updateSymbols()
          @_saveCachedAmount()
          @_validateOrder()

        'click .js-primary-asset-toggle': (e) ->
          e.preventDefault()
          return unless @uiIsEnabled && @formIsEnabled
          @_saveCachedAmount()
          @_togglePrimaryAsset()
          @_updateSymbols()
          @_validateOrder()

        'keydown .js-primary-asset-amount-input' : (e) ->
          @_resetCachedAmounts()
          @_saveCachedAmount()

        'change .js-entity-select-input' : (e) ->
          e.preventDefault()
          return unless @uiIsEnabled && @formIsEnabled
          @_updateRouter()
          # We need to reload in order to get correct balance
          window.location.reload()

        'input .js-primary-asset-amount-input' : (e) ->
          currentAmountInt = parseInt(@_getPrimaryAssetAmount())
          currentAmountIntLength = currentAmountInt.toString().length

          maximumAmountInt = parseInt(@_getPrimaryAssetMaximumNotional())
          maximumAmountIntLength = maximumAmountInt.toString().length

          # If the integer amount typed is bigger than maximum (integer) allowed, and a cached
          # amount is present
          if currentAmountIntLength > maximumAmountIntLength &&
              @cachedAmounts? && @cachedAmounts[@_getPrimaryAssetSymbol()]?
            cachedAmountInt = parseInt(@cachedAmounts[@_getPrimaryAssetSymbol()])

            # If the integer amount typed is higher than the cached amount reset to cache amount
            if currentAmountInt > cachedAmountInt
              @cleave.setRawValue(@cachedAmounts[@_getPrimaryAssetSymbol()])

          @_validateOrder()
          @_renderSecondaryAssetSymbolAndAmount()

        # For some reason, "copy/paste" event are encompassed within "input" event, but not "cut"
        'cut .js-primary-asset-amount-input' : (e) ->
          @$('.js-primary-asset-amount-input').trigger('input')

    new OrderForm el: $this

  order_quoted: ($$, $this) ->
    class OrderQuoted extends Backbone.View
      initialize: ->
        @priceSpreadPercentage = @$el.data('price-spread-percentage')
        @priceWorseForUser = @$el.data('price-worse-for-user')
        @$('.js-wide-spread-alert-wrapper').hide()
        @$('.js-order-confirm-wrapper').show()
        timeFormat = @$('.js-countdown').data('format')
        time = moment(@$('.js-countdown').data('time'))
        timeWithZone = moment.tz(time, moment.tz.guess())
        @startCountdown(timeWithZone, timeFormat)

      startCountdown: (timeWithZone, timeFormat) ->
        @countdown = true
        @$('.js-countdown').countdown timeWithZone.format('M/D/YYYY, H:mm:ss'), (event) =>
          return unless @countdown
          @$('.js-countdown').html(event.strftime(timeFormat))
          if event.offset.totalSeconds <= 0
            Partials.with $(Partials.selector 'quotable_integration/requests_for_quote/order'), (partial) =>
              partial.showOrderForm()
          else if event.offset.totalSeconds <= 10
            @$('.js-countdown').removeClass('u-colorOrange').addClass('u-colorRed')
          else if event.offset.totalSeconds <= 20
            @$('.js-countdown').removeClass('u-colorGreen').addClass('u-colorOrange')

      stopCountdown: ->
        @$('.js-countdown-wrapper').css({opacity: 0.1})
        @countdown = false

      # Wait 20 seconds, if success message hasn't shown, show the internet issues message
      startConfirmTimeout: ->
        @confirmTimeout = setTimeout =>
          Partials.with $(Partials.selector 'quotable_integration/requests_for_quote/order'), (partial) =>
            @$('.js-order-quoted-internet-issues-message').fadeIn('fast')
            @scrollToTop()
        , 20000

      clearConfirmTimeout: ->
        window.clearTimeout(@confirmTimeout) if @confirmTimeout?

      scrollToTop: ->
        Partials.with $(Partials.selector 'layouts/shared/market'), (partial) =>
          partial.scrollToTop()

      showWideSpreadAlert: ->
        @$('.js-wide-spread-alert-wrapper').show()
        @$('.js-order-confirm-wrapper').hide()

      events:
        'click .js-show-order-form': (e) ->
          e.preventDefault()
          $target = $(event.target)
          return if @$('.js-confirm-order, .js-show-order-form').hasClass('c-button--loading')
          @$('.js-confirm-order, .js-confirm-wide-spread, .js-show-order-form').addClass(
            'c-button--loading')
          @stopCountdown()
          $.ajax
            url: $target.attr('href'),
            type: 'PATCH',
            success: (response) =>
              Partials.with $(Partials.selector 'quotable_integration/requests_for_quote/order'), (partial) =>
                partial.showOrderForm()

            error: =>
              Partials.with $(Partials.selector 'quotable_integration/requests_for_quote/order'), (partial) =>
                partial.showOrderForm()


        'click .js-confirm-order': (e) ->
          e.preventDefault()
          return if @$('.js-confirm-order, .js-show-order-form').hasClass('c-button--loading')
          if @priceWorseForUser && Math.abs(@priceSpreadPercentage) > 2.5
            @showWideSpreadAlert()
          else
            $target = $(event.target)
            @$('.js-confirm-order, .js-show-order-form').addClass('c-button--loading')
            @stopCountdown()
            $.ajax
              url: $target.attr('href'),
              type: 'PATCH',
              success: (response) =>
                @startConfirmTimeout()

              error: =>
                Partials.with $(Partials.selector 'quotable_integration/requests_for_quote/order'),
                (partial) =>
                  partial.showOrderForm()
                  partial.showUnexpectedErrorMessage()


        'click .js-confirm-wide-spread': (e) ->
          e.preventDefault()
          $target = $(event.target)
          return if @$('.js-confirm-wide-spread, .js-show-order-form').hasClass('c-button--loading')

          @$('.js-confirm-wide-spread, .js-show-order-form').addClass('c-button--loading')
          @stopCountdown()
          $.ajax
            url: $target.attr('href'),
            type: 'PATCH',
            success: (response) =>
              @startConfirmTimeout()

            error: =>
              Partials.with $(Partials.selector 'quotable_integration/requests_for_quote/order'), (partial) =>
                partial.showOrderForm()
                partial.showUnexpectedErrorMessage()


    new OrderQuoted el: $this
