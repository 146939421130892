import * as React from "react";
import { HistoryLog } from "@coinlist/karma-frontend"
import { ampli } from '../../../../ampli'
require("@coinlist/karma-frontend/dist/style.css");

export default function KarmaHistory(props) {
    return (
        <HistoryLog amplitudeEvent={(event, data) => ampli.track(event, undefined, data)} entityId={props.entityId} apiUrl={"/karma"} />
    );
}
