require('datatables')
require('underscore')
require('magnific-popup')


window.Admin.Entities.Shared =
  table: ($$, $this) ->
    class TableView extends Backbone.View
      initialize: ->
        @createdAtColumn = @$('.js-created_at_column').index()
        options = _.extend(DataTablePresets.default(), {
          order: [[ @createdAtColumn, "desc" ]],
          scrollY: '50vh'
          scrollCollapse: true
        })
        @tableApi = @$('.js-table').DataTable(options)

      events:
        'input .js-search_input': (event) ->
          @tableApi.search(event.currentTarget.value.trim()).draw()


    new TableView el: $this

  vault_balance_table: ($$, $this) ->
    class VaultBalanceTableView extends Backbone.View
      events: ->
        'click .js-cancel_vault_deposit': (event) ->
          if confirm('Are you sure?')
            $target = $(event.target)
            $target.closest('form').ajaxSubmit
              success: (response) =>
                window.location.reload()
              error: (response) =>
                Tooltips.formServerError($target)
        'click .js-force_vault_withdrawl': (event) ->
          if confirm('Are you sure?')
            $target = $(event.target)
            $target.closest('form').ajaxSubmit
              success: (response) =>
                window.location.reload()
              error: (response) =>
                Tooltips.formServerError($target)
    new VaultBalanceTableView el: $this

  offerings_participants_table: ($$, $this) ->
    class OfferingsParticipantsTableView extends Backbone.View
      initialize: ->
        @createdAtColumn = @$('.js-created_at_column').index()
        options = _.extend(DataTablePresets.default(), {
          order: [[ @createdAtColumn, "desc" ]],
          scrollY: '50vh'
          scrollCollapse: true
        })
        @tableApi = @$('.js-table').DataTable(options)

      events:
        'input .js-search_input': (event) ->
          @tableApi.search(event.currentTarget.value).draw()

        'click .js-queue-info': (event) ->
          event.preventDefault()
          $.magnificPopup.open(
            items: {
              src: event.currentTarget.nextElementSibling
            },
            type: 'inline'
          )

    new OfferingsParticipantsTableView el: $this
