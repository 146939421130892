Partials = require('../../lib/partials')

class ReloadablePartial extends Backbone.View
  reload: ->
    @$('.js-spinner').fadeIn()
    $.ajax
      url: @$el.data('reload-path')
      success: (data) =>
        if data.partial_html
          @$el.replaceWith(data.partial_html)

window.Auctions.MultiSellerAuctions.Participants =
  charts: ($$, $this) ->
    new ReloadablePartial el: $this

  balances: ($$, $this) ->
    new ReloadablePartial el: $this

  orders: ($$, $this) ->
    class OrdersView extends ReloadablePartial
      initialize: ->
        @disabledClass = @$el.data('disabled-class')

      reload: ->
        @$el.addClass(@disabledClass)
        super()

    new OrdersView el: $this

  entity_selector: ($$, $this)->
    class EntitySelectView extends Backbone.View
      events: ->
        'change .js-entity': (e) ->
          $target = @$(e.target)
          $target.attr('disabled', 'disabled')
          window.location.href = $target.val()

    new EntitySelectView el: $this

  order_form: ($$, $this) ->
    class OrderFormView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      BUY_PRICE_LABEL = 'Bid Price'
      SELL_PRICE_LABEL = 'Offer Price'

      FIVE_MINUTES_IN_MILLISECONDS = 300000

      initialize: ->
        @decimalPrecision = @$el.data('decimal-precision')

        @navItemModifier = @$el.data('nav-item-modifier')
        @inputErrorClass = @$el.data('input-error-class')
        @campaignFeePercent = @$el.data('campaign-fee-percent')
        @lastTickerPrice = @$el.data('last-ticker-price')
        @targetPrice = @$el.data('target-price')
        @sides = @$el.data('sides')
        @pricesCurrentlyDisplayed = @$el.data('prices-currently-displayed')
        @lockinPhaseStartsAt = @$el.data('lockin-phase-starts-at')
        @activePhaseStartsAt = @$el.data('active-phase-starts-at')
        @settlementPhaseStartsAt = @$el.data('settlement-phase-starts-at')
        @quoteAssetCurrency = @$el.data('quote-asset-currency')

        @$side = @$('.js-side')
        @$amount = @$('.js-amount')
        @$price = @$('.js-price')
        @$fee = @$('.js-fee')
        @$total = @$('.js-total')
        @$submitButton = @$('.js-submit')
        @$amountError = @$('.js-amount-error')
        @$totalAmountError = @$('.js-total-amount-error')
        @$ajaxErrors = @$('.js-ajax-errors')
        @$priceLabel = @$('.js-price_label')
        @$reviewButton = @$('.js-review_button')

        @_validate()
        @_setInputsAsDefaults()

        timeUntilLockinPhaseMilliseconds = new Date(@lockinPhaseStartsAt) - new Date()
        if timeUntilLockinPhaseMilliseconds > 0
          setTimeout(@updateOnLedgerChange, timeUntilLockinPhaseMilliseconds)

        timeUntilActivePhaseMilliseconds = new Date(@activePhaseStartsAt) - new Date()
        timeUntilSettlementPhaseMilliseconds = new Date(@settlementPhaseStartsAt) - new Date()

        # delay by 10 seconds so when it refreshes, it is the new phase for sure
        delay = 10000
        if timeUntilActivePhaseMilliseconds > 0
          setTimeout(@updateOnPhaseTransition, timeUntilActivePhaseMilliseconds + delay)
        else if timeUntilSettlementPhaseMilliseconds > 0
          intervalTimer = setInterval(@updateOnLedgerChange, FIVE_MINUTES_IN_MILLISECONDS)
          setTimeout(clearTimeout, timeUntilSettlementPhaseMilliseconds, intervalTimer)
          setTimeout(@updateOnPhaseTransition, timeUntilSettlementPhaseMilliseconds + delay)

      _round: (amount) ->
        parseFloat(amount.toFixed(@decimalPrecision))

      _setSide: (side) ->
        @$side.val(side)

      _side: ->
        @$side.val()

      _formatAmount: (val) ->
        parseFloat(val.toFixed(@decimalPrecision))

      _amount: ->
        amount = parseFloat(@$amount.val())
        return 0.0 if isNaN(amount)
        @_round(amount)

      _price: ->
        price = parseFloat(@$price.val())
        return 0.0 if isNaN(price)
        @_round(price)

      _total: (amount, price) ->
        if @_side() == @sides.buy
          (price * amount) * (1 + parseFloat(@campaignFeePercent))
        else if @_side() == @sides.sell
          (price * amount) * (1 - parseFloat(@campaignFeePercent))

      _fee: (amount, price) ->
        @_total(amount, price) * parseFloat(@campaignFeePercent)

      _validate: ->
        @$submitButton.addClass(@DISABLED_CLASS)
        @$reviewButton.addClass(@DISABLED_CLASS)
        @$amountError.hide()
        @$totalAmountError.hide()
        @$amount.removeClass(@inputErrorClass)
        @$price.removeClass(@inputErrorClass)

        amount = @_amount()
        price = @_price()

        if amount == 0 || price == 0 || @_total(amount, price) == 0
          @_setTotalAndFeeAsBlank()
          return

        balances = @_getBalances()
        if @_side() == @sides.sell
          if amount > balances.quote
            @$amount.addClass(@inputErrorClass)
            @$amountError.text('Insufficient funds').show()
            return
        else if @_side() == @sides.buy
          if @_total(amount, price) > balances.base
            @$amount.addClass(@inputErrorClass)
            @$price.addClass(@inputErrorClass)
            @$totalAmountError.text('Insufficient funds').show()
            return

        @$total.text(@_formatAmount(@_total(amount, price)))
        @$fee.text(@_formatAmount(@_fee(amount, price)))

        @$reviewButton.removeClass(@DISABLED_CLASS)
        @$submitButton.removeClass(@DISABLED_CLASS)

      _setInputsAsDefaults: ->
        @$amount.val('')
        if @lastTickerPrice > 0
          @$price.val(@lastTickerPrice)
        else if @targetPrice > 0
          @$price.val(@targetPrice)
        else
          @$price.val('')

      _setTotalAndFeeAsBlank: ->
        @$fee.text('')
        @$total.text('')

      _getBalances: ->
        $(Partials.selector('auctions/multi_seller_auctions/participants/balances')).data('balances')

      _setOrderAmountToMax: ->
        balances = @_getBalances()
        if @_side() == @sides.sell
          return if balances.quote == 0
          @$amount.val(parseFloat(balances.quote.toFixed(@decimalPrecision - 1)))

      _setOrderPriceToCurrentPrice: ->
        @$price.val(@lastTickerPrice)

      updateOnLedgerChange: ->
        _.each ['balances', 'orders', 'charts/header', 'charts/price_chart'], (partial_name) ->
          Partials.with Partials.selector('auctions/multi_seller_auctions/participants/' + partial_name), (partial) =>
            partial.reload()

      updateOnPhaseTransition: ->
        _.each ['balances', 'orders', 'charts'], (partial_name) ->
          Partials.with Partials.selector('auctions/multi_seller_auctions/participants/' + partial_name), (partial) =>
            partial.reload()

      events: ->
        'input .js-amount': ->
          @_validate()

        'input .js-price': ->
          @_validate()

        'click .js-nav-side': (e) ->
          $target = @$(e.target)
          side = $target.data('side')
          @_setSide(side)
          @_validate()

          @$('.js-nav-side').removeClass(@navItemModifier)
          $target.addClass(@navItemModifier)

          @$('.js-show-for-side').hide()
          @$(".js-show-for-side[data=#{side}]").show()

          side_text = side.charAt(0).toUpperCase() + side.slice(1);
          @$submitButton.find('.js-side-text').text(side_text)
          @$reviewButton.find('.js-review-side-text').text(side_text)

          if side == @sides.buy
            @$priceLabel.text(BUY_PRICE_LABEL + ' (per ' + @quoteAssetCurrency + ')')
          else if side == @sides.sell
            @$priceLabel.text(SELL_PRICE_LABEL + ' (per ' + @quoteAssetCurrency + ')')

          if side == @sides.buy
            @$('.js-set_to_max_block').hide()
            @$('.js-no_set_to_max_block').show()
          else if side == @sides.sell
            @$('.js-set_to_max_block').show()
            @$('.js-no_set_to_max_block').hide()

          @_setInputsAsDefaults()
          @_setTotalAndFeeAsBlank()

        'click .js-set_to_max': ->
          @_setOrderAmountToMax()
          @_validate()

        'click .js-set_to_current_price': ->
          @_setOrderPriceToCurrentPrice()
          @_validate()

        'click .js-review_button': ->
          @$('.js-form_section').hide()
          @$('.js-review_section').show()

          @$('.js-review_price').text(@_formatAmount(@_price()))
          @$('.js-review_amount').text(@_formatAmount(@_amount()))
          @$('.js-review_fee').text(@$fee.text())
          @$('.js-review_total').text(@$total.text())

        'click .js-back': ->
          @$('.js-form_section').show()
          @$('.js-review_section').hide()
          @$ajaxErrors.hide()

        'click .js-submit': (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$ajaxErrors.hide()

          @$('form').ajaxSubmit
            success: =>
              # reset form UI
              $target.removeClass(@LOADING_CLASS)
              @_setInputsAsDefaults()
              @_setTotalAndFeeAsBlank()
              @_validate()

              # refresh orders
              @updateOnLedgerChange()

              @$('.js-form_section').show()
              @$('.js-review_section').hide()
              @$ajaxErrors.hide()

              $success = @$('.js-success')
              $success.slideDown()
              setTimeout =>
                $success.slideUp(600)
              , 3000

            error: (response) =>
              $target.removeClass(@LOADING_CLASS)
              error = response.responseJSON.errors
              if error.message
                @$ajaxErrors.text(error.message).slideDown()
                @$submitButton.addClass 'c-button--disabled'
              else
                @$ajaxErrors.text('An unknown error occurred').slideDown()
                @$submitButton.addClass 'c-button--disabled'

    new OrderFormView el: $this
