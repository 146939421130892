Partials = require('../../lib/partials')
PrettyAlert = require('../../lib/pretty_alert')

class ReloadablePartial extends Backbone.View
  reload: ->
    @$('.js-spinner').fadeIn()
    $.ajax
      url: @$el.data('reload-path')
      success: (data) =>
        if data.partial_html
          @$el.replaceWith(data.partial_html)

window.Auctions.DutchAuctions.Participants =
  balances: ($$, $this) ->
    new ReloadablePartial el: $this

  bids: ($$, $this) ->
    class BidsView extends ReloadablePartial
      initialize: ->
        @disabledClass = @$el.data('disabled-class')

      reload: ->
        @$el.addClass(@disabledClass)
        super()

    new BidsView el: $this

  bid_form: ($$, $this) ->
    class BidFormView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @decimalPrecision = @$el.data('decimal-precision')
        @inputErrorClass = @$el.data('input-error-class')
        @latestAuctionPrice = @$el.data('latest-auction-price')
        @minimumBidAmount = @$el.data('minimum-bid-amount')
        @minimumBidPrice = @$el.data('minimum-bid-price')
        @maximumBidPrice = @$el.data('maximum-bid-price')
        @campaignState = @$el.data('campaign-state')
        @stoppedState = @$el.data('stopped-state')
        @finalizedState = @$el.data('finalized-state')
        @remainingSupply = @$el.data('remaining-supply')

        @$amount = @$('.js-amount')
        @$price = @$('.js-price')
        @$total = @$('.js-total')
        @$submitButton = @$('.js-submit')
        @$reviewButton = @$('.js-review')
        @$amountError = @$('.js-amount-error')
        @$priceError = @$('.js-price-error')
        @$ajaxErrors = @$('.js-ajax-errors')

        @_setDefaults()
        @_validate()

        Partials.with Partials.selector('web_sockets/dutch_auctions_status'), (partial) =>
          partial.on 'update', (data) =>
            oldLatestAuctionPrice = @latestAuctionPrice
            @latestAuctionPrice = data.auction_price
            @remainingSupply = data.remaining_supply
            if @_price() == parseFloat(oldLatestAuctionPrice)
              @_setToCurrentPrice()
            if data.auction_status != @campaignState && @_campaignHasEnded(data.auction_status)
              window.location.reload()

      _campaignHasEnded: (state) ->
        state == @stoppedState || state == @finalizedState

      _round: (amount) ->
        parseFloat(amount.toFixed(@decimalPrecision))

      _amount: ->
        amount = parseFloat(@$amount.val())
        return 0.0 if isNaN(amount)
        @_round(amount)

      _price: ->
        price = parseFloat(@$price.val())
        return 0.0 if isNaN(price)
        @_round(price)

      _setDefaults: ->
        @$amount.val('')
        @$price.val(@latestAuctionPrice)
        @$total.text('0.00')

      _balance: ->
        $(Partials.selector('auctions/dutch_auctions/participants/balances')).data('balance')

      _setToCurrentPrice: ->
        @$price.val(@latestAuctionPrice)
        @_validate()

      _validate: ->
        @$reviewButton.addClass(@DISABLED_CLASS)
        @$submitButton.addClass(@DISABLED_CLASS)

        @$ajaxErrors.hide()
        @$amountError.hide()
        @$amount.removeClass(@inputErrorClass)
        @$priceError.hide()
        @$price.removeClass(@inputErrorClass)

        amount = @_amount()
        price = @_price()
        total = @_round(amount / price)

        return if amount == 0 || price == 0 || total == 0

        balance = @_balance()
        if amount > balance
          @$amount.addClass(@inputErrorClass)
          @$amountError.text('Insufficient funds').show()
          return
        if amount < @minimumBidAmount
          @$amount.addClass(@inputErrorClass)
          @$amountError.text('Bid amount needs to be at least ' + @minimumBidAmount).show()
          return
        if price < @minimumBidPrice
          @$price.addClass(@inputErrorClass)
          @$priceError.text('Bid price needs to be at least ' + @minimumBidPrice).show()
          return
        if price > @maximumBidPrice
          @$price.addClass(@inputErrorClass)
          @$priceError.text('Bid price can be at most ' + @maximumBidPrice).show()
          return

        @$total.text(total)
        @$reviewButton.removeClass(@DISABLED_CLASS)
        @$submitButton.removeClass(@DISABLED_CLASS)

      updateOnLedgerChange: ->
        _.each ['balances', 'bids', 'chart'], (partial_name) ->
          Partials.with Partials.selector('auctions/dutch_auctions/participants/' + partial_name), (partial) =>
            partial.reload()

      events: ->
        'input .js-amount': ->
          @_validate()

        'input .js-price': ->
          @_validate()

        'click .js-review': ->
          @$('.js-form_section').hide()
          @$('.js-review_section').show()

          amount = @_amount()
          price = @_price()
          total = @_round(amount / price)
          @$('.js-review_amount').text(amount)
          @$('.js-review_price').text(price)
          @$('.js-review_total').text(total)

        'click .js-back': ->
          @$('.js-form_section').show()
          @$('.js-review_section').hide()

        'click .js-set-to-max': ->
          remaining_supply = @remainingSupply
          balance = @_balance()
          if parseFloat(remaining_supply) < parseFloat(balance)
            @$amount.val(remaining_supply)
          else
            @$amount.val(balance)
          @_validate()

        'click .js-set-to-current-price': ->
          @_setToCurrentPrice()

        'click .js-submit': (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$ajaxErrors.hide()
          if @_price() > parseFloat(@latestAuctionPrice)
            PrettyAlert.alert('The auction price has changed. Please refresh the page and submit a new bid.')
            return

          @$('form').ajaxSubmit
            success: =>
              # reset form UI
              $target.removeClass(@LOADING_CLASS)
              @_setDefaults()
              @_validate()

              # update sections
              @updateOnLedgerChange()

              @$('.js-form_section').show()
              @$('.js-review_section').hide()
              @$ajaxErrors.hide()

              $success = @$('.js-success')
              $success.slideDown()
              setTimeout =>
                $success.slideUp(600)
              , 3000

            error: (response) =>
              $target.removeClass(@LOADING_CLASS)
              error = response.responseJSON.errors
              if error.message
                @$ajaxErrors.text(error.message).slideDown()
                @$submitButton.addClass 'c-button--disabled'
              else
                @$ajaxErrors.text('An unknown error occurred').slideDown()
                @$submitButton.addClass 'c-button--disabled'

    new BidFormView el: $this

  celo_self_custody_interest: ($$, $this) ->
    class CeloSelfCustodyInterestView extends Backbone.View

      events: ->
        'submit form.js-celo-self-custody-form': (event) ->
          event.preventDefault()
          $form = @$(event.target)
          $button = @$('.js-submit')

          return if $button.hasClass('c-button--loading')
          $button.addClass 'c-button--loading'

          $form.ajaxSubmit
            success: (response) =>
              @$('.js-show-on-success').show()
              @$('.js-form-section').hide()
              @$('.js-celo-self-custody-module').fadeOut(4000)

            error: (response) =>
              if response.status == 422
                $html = $(response.responseJSON.html)
                @$el.replaceWith $html
                $error = $html.find('.c-input-group--has-error,.js-error').first()
                if $error
                  $error = Animate.niceScrollTo($error, 700)
              else
                Tooltips.formServerError($button)

    new CeloSelfCustodyInterestView el: $this

  chart: ($$, $this) ->
    class ChartView extends Backbone.View
      USD = 'USD'

      LINE_COLOR = '#1581f3'
      GRID_LINE_COLOR = '#eaebec'
      LABEL_COLOR = '#333'

      initialize: ->
        Chart.platform.disableCSSInjection = true
        Chart.defaults.global.defaultFontFamily = '"Akkurat", "Helvetica Neue", sans-serif'

        @$remainingSupply = @$('.js-remaining-supply')

        @flashGreenClass = @$el.data('flash-green-class')
        chartData = @$el.data('chart-data')

        @priceChart = new Chart @$('.js-chart'),
          type: 'line'
          data: @_chartData(chartData)
          options: @_chartOptions(chartData)
          plugins: [@_chartDrawTotalTokenSupplyPlugin(chartData)]

        Partials.with Partials.selector('web_sockets/dutch_auctions_status'), (partial) =>
          partial.on 'update', (data) =>
            old_price = @$('.js-current-price').text()
            old_remaining_supply = @$remainingSupply.text()
            @$('.js-current-price').text(data.auction_price)
            @$remainingSupply.text(data.remaining_supply)
            @_updatePriceChart(data.chart_price_data)
            if old_price != data.auction_price
              @_flashPrice()
            if old_remaining_supply != data.remaining_supply
              @_flashTotalCommitted()

      _updatePriceChart: (chartPriceData) ->
        currentPriceData = @priceChart.data.datasets[0].data
        currentPriceData.push(chartPriceData[chartPriceData.length - 1])
        @priceChart.update()

      _flashPrice: ->
        $price = @$('.js-current-price')
        flashGreenClass = @flashGreenClass
        $price.addClass(flashGreenClass)
        setTimeout(->
          $price.removeClass(flashGreenClass)
        , 2000)

      _flashTotalCommitted: ->
        $remainingSupply = @$remainingSupply
        flashGreenClass = @flashGreenClass
        $remainingSupply.addClass(flashGreenClass)
        setTimeout(->
          $remainingSupply.removeClass(flashGreenClass)
        , 2000)

      _chartData: (chartData) ->
        {
          datasets: [
            {
              data: chartData.data,
              steppedLine: true,
              backgroundColor: '#00000008',
              pointRadius: 0,
              borderColor: LINE_COLOR,
              borderWidth: 2,
            },
            {
              data: chartData.bids
              hidden: true
            }
          ]
        }

      # Using Chartjs plugins gives us more control over custom data drawing
      _chartDrawTotalTokenSupplyPlugin: (chartData) ->
        chartView = @

        # The plugin
        {
          afterDatasetDraw: (chartInstance, { index, meta, easingValue }) ->
            ctx = chartInstance.ctx

            # display price
            dataset = chartInstance.data.datasets[0]
            if dataset.data.length > 0
              last_point_value = _.last(dataset.data)

              model = _.last(dataset._meta[0].data)._model
              return unless model

              # display price value
              ctx.fillStyle = '#888'
              ctx.font = 'bold 13px "Akkurat", "Helvetica Neue", sans-serif'

              if last_point_value.y / chartData.supply > 0.5
                tokens_remaining_text_y = model.y + 5
                tokens_remaining_quantity_y = model.y + 25
              else
                tokens_remaining_text_y = model.y - 15
                tokens_remaining_quantity_y = model.y + 5

              priceRange = chartData.bounds.max - chartData.bounds.min
              if (chartData.bounds.max - last_point_value.x) / priceRange > 0.8
                tokens_remaining_text_x = model.x - 140
                tokens_remaining_quantity_x = model.x - 140
              else
                tokens_remaining_text_x = model.x + 15
                tokens_remaining_quantity_x = model.x + 15

              ctx.fillText('TOKENS CLAIMED', tokens_remaining_text_x, tokens_remaining_text_y)
              ctx.fillStyle = LINE_COLOR
              ctx.font = 'bold 15px "Akkurat", "Helvetica Neue", sans-serif'
              ctx.fillText(
                chartView._formatAmount(last_point_value.y,
                                        chartData.quote_currency,
                                        chartData.total_accepted_bid_amount_decimal_places),
                tokens_remaining_quantity_x, tokens_remaining_quantity_y
              )

              # display a current price indicator
              ctx.beginPath();
              ctx.arc(model.x, model.y, 2, 0, 2 * Math.PI);
              ctx.strokeStyle = LINE_COLOR
              ctx.lineWidth = 4
              ctx.stroke();

              ctx.beginPath();
              ctx.arc(model.x, model.y, 5, 0, 2 * Math.PI);
              ctx.strokeStyle = '#1581f333'
              ctx.lineWidth = 10
              ctx.stroke();

            # show bid numbers
            dataset = chartInstance.data.datasets[1]
            for dataPoint, index in chartData.bids
              model = dataset._meta[0].data[index]._model
              bid_tick_x = model.x - 3

              ctx.beginPath();
              ctx.arc(bid_tick_x, chartInstance.chartArea.bottom, 6, 0, 2 * Math.PI);
              ctx.fillStyle = '#000'
              ctx.fill();

              ctx.fillStyle = '#fff'
              ctx.font = 'bold 9px "Akkurat", "Helvetica Neue", sans-serif'
              x_correction = if dataPoint.y >= 10 then 5 else 2.4
              ctx.fillText(dataPoint.y, bid_tick_x - x_correction, chartInstance.chartArea.bottom + 3)
        }

      _chartOptions: (chartData) ->
        chartView = @
        ctx = @$('.js-chart')[0].getContext("2d")
        label_location = chartData.supply * 0.95
        {
          legend:
            display: false

          layout:
            padding:
              right: 35
              left: 5
              top: 20
          scales:
            xAxes: [{
              type: 'linear'
              scaleLabel: {
                display: true
                labelString: chartData.x_axis_label + " (" + chartData.base_currency + ")"
              }
              ticks:
                maxTicksLimit: 7
                min: chartData.bounds.min
                max: chartData.bounds.max
                tickMarkLength: 0
                lineHeight: 1.4
                fontColor: LABEL_COLOR
                reverse: true
              gridLines:
                display: false
                color: GRID_LINE_COLOR
            }]
            yAxes: [{
              scaleLabel: {
                display: true
              }
              ticks:
                maxTicksLimit: 6
                max: chartData.supply
                beginAtZero: true
                fontColor: LABEL_COLOR
                callback: (label, _index, _labels) ->
                  chartView._formatAmount(parseInt(label), chartData.quote_currency)
              gridLines:
                color: GRID_LINE_COLOR
                zeroLineColor: GRID_LINE_COLOR
            }]
          hover:
            animationDuration: 0
            mode: 'nearest'
            intersect: false
          responsiveAnimationDuration: 0
          tooltips:
            mode: 'nearest'
            intersect: false
            backgroundColor: 'rgba(255,255,255,0.93)'
            titleFontSize: 14
            titleFontColor: '#666'
            titleSpacing: 10
            titleMarginBottom: 14
            bodyFontSize: 14
            bodyFontColor: '#666'
            xPadding: 14
            yPadding: 14
            cornerRadius: 3
            displayColors: false
            borderColor: '#ddd'
            borderWidth: 1
            caretSize: 8
            callbacks:
              title: (tooltipItem, data) =>
                price = chartData.data[tooltipItem[0].index].price
                'Price: ' + chartView._formatAmount(price, chartData.base_currency)
              label: (tooltipItem, data) =>
                'Tokens Claimed: ' +
                  chartView._formatAmount(tooltipItem.yLabel, chartData.quote_currency, 0)
          annotation:
            drawTime: 'afterDatasetDraw'
            annotations: [
              id: "hline"
              type: "line"
              mode: "horizontal"
              scaleID: "y-axis-0"
              value: chartData.supply
              borderColor: '#555555'
              borderWidth: 1
              borderDash: [2, 2]
              label:
                position: "bottom"
                backgroundColor: "white"
                content: "Total Supply"
                enabled: true
                fontSize: '10'
                fontColor: '#777777'
                fontStyle: 'normal'
                fontFamily: "Akkurat, Roboto, san-serif"
                yAdjust: 15
            ]
          animation:
            duration: 0
        }

      _formatAmount: (amount, baseCurrency, decimalPlaces = -1) ->
        amount = amount.toFixed(decimalPlaces) if decimalPlaces >= 0
        if baseCurrency == USD
          "$#{amount}"
        else
          "#{amount} #{baseCurrency}"

      reload: ->
        $.ajax
          url: @$el.data('chart-data-path')
          success: (chartData) =>
            @priceChart.data = @_chartData(chartData)
            @priceChart.options = @_chartOptions(chartData)
            @priceChart.plugins = [@_chartDrawTotalTokenSupplyPlugin(chartData)]
            @priceChart.update()

    new ChartView el: $this
