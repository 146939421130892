Partials = require('../../../lib/partials')
PrettyAlert = require('../../../lib/pretty_alert')

window.Auctions.DutchAuctions.Participants.Bids =
  row: ($$, $this) ->
    class RowView extends Backbone.View
      initialize: ->
        @disabledClass = @$el.data('disabled-class')

        Partials.with Partials.selector('web_sockets/dutch_auctions_status'), (partial) =>
          partial.on 'update', (data) =>
            Partials.with Partials.selector('auctions/dutch_auctions/participants/bids'), (partial) =>
              partial.reload()
#            TODO: use websockets to update bids individually
#            @$('.js-potential-tokens').each (i, element) =>
#              bidAmount = $(element).data('bid')
#              maxTokens = $(element).data('maxTokens')
#              potentialTokens = Math.min(maxTokens, bidAmount / data.auction_price)
#              $(element).text(potentialTokens)

      events: ->
        'click .js-delete': ->
          PrettyAlert.confirm 'Are you sure you want to remove the bid?', success: =>
            @$el.addClass(@disabledClass)
            @$('form').ajaxSubmit
              success: =>
                Partials.with Partials.selector('auctions/dutch_auctions/participants/bid_form'), (partial) =>
                  partial.updateOnLedgerChange()

    new RowView el: $this
